
    import {Vue, Component} from 'vue-property-decorator';
    import FontAwesomeIcon from "Components/common/font-awesome-icon.vue";

    @Component<AppError>({
        metaInfo() {
            return {
                bodyAttrs: {
                    class: ['bg-primary', 'text-white']
                },
                title: `Error`,
            };
        },
        components: {
            FontAwesomeIcon
        }
    })
    export default class AppError extends Vue {
        detail: string = '';
        header: string = '';

        get user() {
            return this.$store.getters.getUser;
        }

        mounted() {
            switch (this.$route.name) {
                case 'error404':
                    this.header = 'This page does not exist';
                    this.detail = 'Please double-check the URL provided by your contact, and try again.';
                    break;

                default:
                    this.header = 'An unexpected error occured';
            }
        }

        // showAppErrorModal() {
        //     this.$bvModal.show( 'appErrorModal' );
        // }
    }
