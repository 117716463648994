import { AxiosResponse } from "axios";
import { Error, FileTypes, ProxyResponse } from "Store/store";

export enum Status {
    ACTIVE = 'active',
    DRAFT = 'draft',
    INACTIVE = 'inactive',
    INVITED = 'invited',
    LOCKED = 'locked',
    VALID = 'valid',
    INVALID = 'invalid',
    UNREGISTERED = 'unregistered',
    INITIALIZED = 'initialized'
}

export enum Actions {
    REGISTER = 'register',
    USER_RESET = 'user-reset',
    USER_FORGOT_PW_RESET = 'user-forgot-reset'
}

export enum GroupDefinitionType {
    INCLUDE = 'include',
    EXCLUDE = 'exclude'
}

export enum RequestStatus {
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    PROXY_ERROR = 'PB_PROXY_ERROR'
}

export enum RequestResponse {
    ALL_RESULTS = 'All results displayed',
    RESULTS_DISPLAYED = 'results displayed',
    NO_RESULTS = 'No results',
    UPDATE_SUCCESS = 'Your update was successful!'
}

export enum UserStatus {
    INACTIVE = 'USER_INACTIVE',
    INACTIVE_FOR_ACCOUNT = 'INACTIVE_FOR_ACCOUNT',
    LOCKED = 'USER_LOCKED',
    USER_2FA_EMAIL_SENT = 'USER_2FA_EMAIL_SENT'
}

export enum RequestError {
    CREDENTIAL_CHECK_FAILED = 'Credentials check failed',
    DEFAULT_API = 'Unable to retrieve data at this time',
    INVALID_RESPONSE = 'Invalid response',
    INVALID_REQUEST = 'Invalid request',
    INVALID_REQUEST_ORIGIN = 'Invalid request origin',
    INVALID_PROFILE = `Invalid profile`,
    INVALID_USER = 'Invalid user',
    INVALID_CURRENT_ACCOUNT = 'No valid current account',
    LOGIN_FAILED = 'Login failed',
    MAX_USERS = 'Maximum number of active external users reached',
    // NO_PERSONA = 'No persona was selected',
    SOMETHING_WRONG = 'Something went wrong',
    TOO_MANY_ATTEMPTS = 'Too many attempts',
    UNABLE_TO_ADD = 'Unable to add ',
    UNABLE_TO_PARSE = 'Unable to parse',
    UNABLE_TO_RETRIEVE = 'Unable to retrieve',
    UNABLE_TO_UPDATE = 'Unable to update ',
    UNABLE_TO_VALIDATE = 'Unable to validate ',
    UNAUTHORIZED = 'Unauthorized request',
    UNAUTHORIZED_ACCOUNT = 'Unauthorized account request',
    UNAUTHORIZED_ADMIN = 'Unauthorized admin request',
    UNAUTHORIZED_DEVICE = 'Unauthorized device'
}

export enum ObjectMode {
    NEW = 'new',
    EDIT = 'edit',
    VIEW = 'view',
    RESET = 'reset',
    REFRESH = 'refresh'
}

export const supportHtmlEmailLink: string = `<a href="mailto:PersonaBuilder@wiland.com" target="_blank">PersonaBuilder Support</a>`;
export const invalidRequestResponse: ProxyResponse = { code: 401, status: RequestError.INVALID_REQUEST };
export const invalidAuthResponse: ProxyResponse = { code: 401, status: RequestError.UNAUTHORIZED };
export const invalidCredentialsError: string = `${RequestError.LOGIN_FAILED}. Please check your credentials.`;
export const invalid2faError: string = `This code is incorrect, please enter the correct code.`;
export const invalid2faMaxError: string = `You've failed to enter the correct code after too many attempts. You may try to log in again here.`;
export const inactiveAccountHtmlError: string = `<span class="text-danger">Your account is inactive.</span><br />Please contact ${supportHtmlEmailLink}`;
export const lockedAccountHtmlError: string = `<span class="text-danger">Your account is locked.</span><br />Please contact ${supportHtmlEmailLink}`;
export const currentAccountHtmlError: string = `<span class="text-danger">There is an issue with your current account.</span><br />Please contact ${supportHtmlEmailLink}`;
export const unregisteredUserHtmlError: string = `<span class="text-danger">You have not completed user registration.</span><br />Please contact ${supportHtmlEmailLink}`;
export const somethingWentWrong: string = `${RequestError.SOMETHING_WRONG}. Please contact ${supportHtmlEmailLink}`;
export const defaultError: Error = { message: '' };
export const emailPlaceholder: string = 'email@domain.com';
export const phonePlaceholder: string = '(555) 555-5555';
export const passwordMaskPlaceholder: string = '*********';
export const instagram: string = 'instagram';
export const twitter: string = 'twitter';
export const maxInvalidLogins: number = 6;
export const defaultJobPriority: number = 5;

export const defaultAxiosResponse: AxiosResponse = {
    data: {},
    status: 0,
    statusText: '',
    headers: {},
    config: {}
}

export const logoTypes: FileTypes = {
    mime: [ 'image/gif', 'image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml' ],
    ext: [ 'gif', 'png', 'jpg', 'jpeg', 'svg' ]
};

export const logoRequirements: Array<string> = [
    'Logo files will optimally be formatted to be displayed at ~35px high.',
    'Transparent or white backgrounds for the logos are preferred.',
    `Image file type may be ${logoTypes.ext.join().replace( /,/g, ', ').toUpperCase()}`
];
