var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "article",
    [
      _c("div", { staticClass: "row align-items-start mb-3" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "h1",
            { staticClass: "h3 mb-0" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fad", "cogs"], "fixed-width": "" }
              }),
              _vm._v(" Settings")
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-auto text-size-md text-italic text-light" },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip:hover",
                    arg: "hover"
                  }
                ],
                attrs: { title: _vm.reportVersion.description }
              },
              [_vm._v("Report Version: " + _vm._s(_vm.reportVersion.version))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "mx-auto w-50" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "card mb-5" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("div", { staticClass: "row flex-row align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "minimumThresholdPercentSlider",
                          min: "0",
                          max: _vm.thresholdPercent.max,
                          type: "range"
                        },
                        model: {
                          value: _vm.minimumThresholdPercent,
                          callback: function($$v) {
                            _vm.minimumThresholdPercent = $$v
                          },
                          expression: "minimumThresholdPercent"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { append: "%", size: "sm" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "minimumThresholdPercentInput",
                              min: "0",
                              max: _vm.thresholdPercent.max,
                              type: "number"
                            },
                            model: {
                              value: _vm.minimumThresholdPercent,
                              callback: function($$v) {
                                _vm.minimumThresholdPercent = $$v
                              },
                              expression: "minimumThresholdPercent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("b-form-text", [
                  _vm._v(
                    "\n                            Use this filter to exclude elements where the selected characteristic is below the threshold within the element's overall size. Set to "
                  ),
                  _c("strong", [_vm._v("0%")]),
                  _vm._v(" to disable this filter.\n    ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("div", { staticClass: "row flex-row align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "minimumThresholdCountSlider",
                          min: "0",
                          max: _vm.thresholdCount.max,
                          step: _vm.thresholdCount.step,
                          type: "range"
                        },
                        model: {
                          value: _vm.minimumThresholdCount,
                          callback: function($$v) {
                            _vm.minimumThresholdCount = $$v
                          },
                          expression: "minimumThresholdCount"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "minimumThresholdCountInput",
                          min: "0",
                          max: _vm.thresholdCount.max,
                          size: "sm",
                          step: _vm.thresholdCount.step,
                          type: "number"
                        },
                        model: {
                          value: _vm.minimumThresholdCount,
                          callback: function($$v) {
                            _vm.minimumThresholdCount = $$v
                          },
                          expression: "minimumThresholdCount"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("b-form-text", [
                  _vm._v(
                    "\n                            Use this filter to exclude elements where the selected characteristic has a record count below the threshold. Set to "
                  ),
                  _c("strong", [_vm._v("0")]),
                  _vm._v(" to disable this filter.\n    ")
                ])
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _vm.excludedSummaryDataElements.length
          ? _c("section", { staticClass: "card mb-5" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h5", [_vm._v("Summary chart exclusions")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row align-items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("b-form-input", {
                          attrs: { placeholder: "Search by ID or Name" },
                          model: {
                            value: _vm.excludeSummaryDataElementsFilter,
                            callback: function($$v) {
                              _vm.excludeSummaryDataElementsFilter = $$v
                            },
                            expression: "excludeSummaryDataElementsFilter"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("pagination", {
                      staticClass: "col-auto m-0",
                      attrs: {
                        "current-page":
                          _vm.pagination.excludedSummaryDataElements
                            .currentPage,
                        "item-count":
                          _vm.pagination.excludedSummaryDataElements.itemCount,
                        "items-per-page":
                          _vm.pagination.excludedSummaryDataElements
                            .itemsPerPage,
                        "items-per-page-options": [10, 25, 50]
                      },
                      on: {
                        "pagination:click":
                          _vm.paginateToExcludedSummaryDataElements,
                        "pagination:page-size":
                          _vm.paginationSizeExcludedSummaryDataElements
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("table", { staticClass: "table table-striped table-sm" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "table-cell-min px-3" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            attrs: {
                              href: "",
                              title: `Restore all excluded items to summary charts`
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.confirmRestoreExcludedSummaryDataElements()
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "undo"] }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("ID")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Name")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.paginatedExcludedSummaryDataElements, function(
                      detailItem
                    ) {
                      return _c("tr", [
                        _c("td", { staticClass: "table-cell-min px-3" }, [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                href: "",
                                title: `Restore ${detailItem.dataElementId} to summary charts`
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.restoreExcludedSummaryDataElement(
                                    detailItem
                                  )
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fad", "undo"] }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(detailItem.dataElementId))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(detailItem.shortDescription))])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.allowFilterDataElementType("index")
          ? _c("section", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "custom-control custom-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterDataElementType["index"],
                      expression: "filterDataElementType['index']"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    id: "filterDataElements_index",
                    role: "switch",
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.filterDataElementType["index"])
                      ? _vm._i(_vm.filterDataElementType["index"], null) > -1
                      : _vm.filterDataElementType["index"]
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.filterDataElementType["index"],
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.filterDataElementType,
                              "index",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.filterDataElementType,
                              "index",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.filterDataElementType, "index", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "filterDataElements_index" }
                  },
                  [
                    _vm._v(
                      "Prevent all INDEX data elements from appearing anywhere in the report"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openDataElementList("index")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "Click here to view a list of applicable data elements"
                      )
                    ]
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", id: "dataElementList_index", size: "lg" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("INDEX Data Elements")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("section", [
            _vm.filterDataElementType["index"]
              ? _c("table", { staticClass: "table table-striped table-sm" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("ID")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Name")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.filteredDataElementType("index"), function(
                      detailItem
                    ) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(detailItem.dataElementId))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(detailItem.shortDescription))])
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert alert-warning" }, [
      _vm._v("Settings apply to "),
      _c("strong", [_vm._v("all")]),
      _vm._v(" PDA reports you view. You may change these at any time.")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-title" }, [
      _c("h5", [_vm._v("Minimum Record Thresholds")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }