
    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon';
    import Pagination from 'Common/pagination.vue';
    import * as Utils from 'Utilities/utils';
    import {dataElementTypes} from 'Utilities/reports';

    @Component<Settings>({
        components: {
            Pagination,
            FontAwesomeIcon,
        }
    })
    export default class Settings extends Vue {
        // @Prop({
        //     type: Object,
        //     default: () => {
        //         return {};
        //     }
        // })
        // chartData: any;

        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        detail: any;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params: any;

        Utils = Utils;
        defaultPageVersion: any = {
            description: 'No information available',
            version: 'Unknown',
        };
        excludeSummaryDataElementsFilter: string = '';
        filterDataElementType: any = {
            index: false,
        };
        minimumThresholdCount: number = this.thresholdCount?.value || 0;
        minimumThresholdPercent: number = this.thresholdPercent?.value || 0;
        pagination: any = {
            excludedSummaryDataElements: {
                currentPage: 1,
                itemsPerPage: 10,
                itemCount: 0
            }
        };

        mounted() {
            this.filterDataElementType.index = this.params['filterDataElementType.index']?.value || false;
        }

        get dataElements() {
            return this.detail.report.stats;
        }

        get excludedSummaryDataElements(): any[] {
            return this.params['excludedDataElements.summary'].value
                .map((dataElementId: string) => this.dataElements.find((element: any) => element.dataElementId === dataElementId))
                .filter((element: any) => element !== undefined);
        }

        get filteredExcludedSummaryDataElements(): any[] {
            const searchTerm = this.excludeSummaryDataElementsFilter.toLowerCase();
            return this.excludedSummaryDataElements
                .filter((element: any) => element.dataElementId.toLowerCase().indexOf(searchTerm) > -1
                    || element.shortDescription.toLowerCase().indexOf(searchTerm) > -1);
        }

        get paginatedExcludedSummaryDataElements(): any[] {
            const start = (this.pagination.excludedSummaryDataElements.currentPage - 1) * this.pagination.excludedSummaryDataElements.itemsPerPage,
                end = start + this.pagination.excludedSummaryDataElements.itemsPerPage;
            return this.filteredExcludedSummaryDataElements.slice(start, end);
        }

        get reportVersion(): any {
            return this.detail.pageVersion || this.defaultPageVersion;
        }

        get thresholdCount(): number {
            return this.params.thresholdCount;
        }

        get thresholdPercent(): number {
            return this.params.thresholdPercent;
        }

        allowFilterDataElementType(type: string): boolean {
            switch (type) {
                case 'index':
                    return this.dataElements.some((dataElement: any) => dataElement.dataElementType === dataElementTypes.index);
            }

            return false;
        }

        confirmRestoreExcludedSummaryDataElements() {
            const confirmed = confirm('This will restore all data elements to summary charts. Do you wish to continue?');
            if (confirmed) {
                this.$emit('change-setting', {
                    setting: 'excludedDataElements.summary',
                    value: [],
                });
            }
        }

        filteredDataElementType(type: string): any[] {
            return Utils.sortByProperty(
                this.dataElements.filter((element: any) => element.dataElementType === dataElementTypes[type]),
                'dataElementId',
                'asc'
            );
        }

        openDataElementList(type: string) {
            console.log(`Showing modal: dataElementList_${type}`);
            this.$bvModal.show(`dataElementList_${type}`);
        }

        paginateToExcludedSummaryDataElements(toPage: number) {
            this.pagination.excludedSummaryDataElements.currentPage = toPage;
        }

        paginationSizeExcludedSummaryDataElements(itemsPerPage: number) {
            // this.pagination.currentPage = 1;
            this.pagination.excludedSummaryDataElements.itemsPerPage = itemsPerPage;
        }

        restoreExcludedSummaryDataElement(dataElement: any) {
            // TODO: if this shrinks the page count, paginate back first!
            this.$emit('change-setting', {
                setting: 'excludedDataElements.summary',
                value: this.params['excludedDataElements.summary'].value
                    .filter(dataElementId => dataElementId !== dataElement.dataElementId),
            });
        }

        @Watch('minimumThresholdCount')
        onMinimumThresholdCountChanged() {
            this.minimumThresholdCount = Math.min(this.minimumThresholdCount, this.thresholdCount.max);
            this.$emit('change-setting', {
                setting: 'thresholdCount',
                value: this.minimumThresholdCount,
            });
        }

        @Watch('minimumThresholdPercent')
        onMinimumThresholdPercentChanged() {
            this.minimumThresholdPercent = Math.min(this.minimumThresholdPercent, this.thresholdPercent.max);
            this.$emit('change-setting', {
                setting: 'thresholdPercent',
                value: this.minimumThresholdPercent,
            });
        }

        @Watch('excludeSummaryDataElementsFilter')
        onExcludeSummaryDataElementsFilterChanged(newValue: number, oldValue: number) {
            this.paginateToExcludedSummaryDataElements(1);
        }

        @Watch('filteredExcludedSummaryDataElements', {immediate: true})
        onExcludedSummaryDataElementsChanged(newValue: any[], oldValue: any[]) {
            const itemCount = newValue.length,
                previousItemCount = oldValue?.length || 0,
                pageCount = Math.ceil(itemCount / this.pagination.excludedSummaryDataElements.itemsPerPage),
                previousPageCount = Math.ceil(previousItemCount / this.pagination.excludedSummaryDataElements.itemsPerPage);

            if (previousPageCount && previousPageCount !== pageCount && this.pagination.excludedSummaryDataElements.currentPage > pageCount) {
                // console.log('--> PAGINATE TO PREVIOUS PAGE...')
                this.paginateToExcludedSummaryDataElements(pageCount);
            }

            this.pagination.excludedSummaryDataElements.itemCount = itemCount;
        }

        @Watch('filterDataElementType', {deep: true})
        onFilterDataElementTypeChanged(newValue: any[], oldValue: any[]) {
            this.$emit('change-setting', {
                setting: 'filterDataElementType.index',
                value: this.filterDataElementType.index,
            });
        }
    }
