var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showSubnav && _vm.showForRoute
    ? _c("div", { staticClass: "subnav container-fluid p-0 sticky-top" }, [
        _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
          _c(
            "ul",
            {
              staticClass: "navbar-nav nav-tabs navbar-collapse",
              class: {
                wizard:
                  _vm.subnav.hasOwnProperty("type") &&
                  _vm.subnav.type === "wizard"
              }
            },
            [
              _vm._l(_vm.subnav.links, function(link, index) {
                return [
                  _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      class: {
                        active: _vm.isActive(link),
                        "dropdown dropdown-bubble": link.hasOwnProperty("links")
                      },
                      attrs: { title: link.title }
                    },
                    [
                      link.hasOwnProperty("links")
                        ? [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "nav-link no-color",
                                class: { disabled: _vm.isDisabled(link) },
                                attrs: {
                                  "aria-expanded": "false",
                                  "aria-haspopup": "true",
                                  "data-toggle": "dropdown",
                                  disabled: _vm.isDisabled(link),
                                  href: "#",
                                  id: `subnavDropdown${index}`,
                                  role: "button",
                                  title: _vm.isDisabled(link)
                                    ? "Coming Soon"
                                    : ""
                                }
                              },
                              [
                                link.hasOwnProperty("icon")
                                  ? _c("font-awesome-icon", {
                                      attrs: { icon: link.icon }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(link.label) }
                                }),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  staticClass: "ml-1 mb-n1",
                                  attrs: { icon: "angle-down" }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                class: {
                                  "dropdown-menu-right":
                                    index >= _vm.subnav.links.length / 2
                                },
                                attrs: {
                                  "aria-labelledby": `subnavDropdown${index}`
                                }
                              },
                              _vm._l(link.links, function(menuLink, index) {
                                return _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    key: menuLink.label,
                                    staticClass: "dropdown-item",
                                    class: {
                                      disabled: _vm.isDisabled(menuLink)
                                    },
                                    attrs: {
                                      disabled: _vm.isDisabled(menuLink),
                                      title: _vm.isDisabled(menuLink)
                                        ? "Coming Soon"
                                        : "",
                                      to: {
                                        name: menuLink.name,
                                        params: menuLink.params,
                                        query: menuLink.query
                                      }
                                    }
                                  },
                                  [
                                    menuLink.hasOwnProperty("icon")
                                      ? _c("font-awesome-icon", {
                                          attrs: { icon: menuLink.icon }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(menuLink.label)
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                        : [
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "nav-link",
                                class: { disabled: _vm.isDisabled(link) },
                                attrs: {
                                  disabled: _vm.isDisabled(link),
                                  title: _vm.isDisabled(link)
                                    ? "Coming Soon"
                                    : link.detail?.tooltip || "",
                                  to: {
                                    name: link.name,
                                    params: link.params,
                                    query: link.query
                                  }
                                }
                              },
                              [
                                link.hasOwnProperty("icon")
                                  ? _c("font-awesome-icon", {
                                      attrs: { icon: link.icon }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(link.label) }
                                })
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }