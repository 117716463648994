/**
 * @name            emailMatcher
 * @description     Matches valid email characters based on RFC 5322 standard
 */
export const emailMatcher = RegExp( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );

/**
 * @name            fileExtensionMatcher
 * @description     Matches standard valid file extensions
 */
export const fileExtensionMatcher = RegExp( /\.([^.]*?)(?=\?|#|$)/ );

/**
 * @name
 * @description     Matches ISO 8601 date format yyyy-mm-dd
 */
export const iso8601Date = RegExp( /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/ );

/**
 * @name            illegalIandaChars
 * @description     Matches most common - not an exhaustive list
 */
export const illegalIandaChars: RegExp = /[\.\?!,‚„;:\-–—\\\/\(\)\[\]\{\}`'"‘‛’“‟”…]/g;

/**
 * @name            atSign
 * @description     Matches the @ character only
 */
export const atSign: RegExp = /@$/g;

/**
 * @name            poundSign
 * @description     Matches the # character only
 */
export const poundSign: RegExp = /#$/g;

/**
 * @name            illegalTwitterChars
 * @description     Matches for diacritics or non-Latin characters
 */
export const illegalTwitterChars: RegExp = /\W/g;

/**
 * @name            illegalTwitterContentChars
 * @description     Matches:
 *                  1. Number-only hashtags
 *                  2. Usernames or hashtags with only dirty chars
 *                  3. Literally just an @ or just a #
 *                  4. Strings with an @ or a #, but it's not the first char. (per Louis)
 */
export const illegalTwitterContentChars: RegExp = /^(?:#\d+|@|#|.+@.*|.+#.*)$/;

/**
 * @name            illegalTwitterHandleChars
 * @description     Matches:
 *                  1. Strings without an @ as the first character
 *                  2. Handles with only dirty chars
 *                  3. Literally just an @
 */
export const illegalTwitterHandleChars: RegExp = /^(?:[^@].*|@)$/;

/**
 * @name            illegalHashtagChars
 * @description     Matches:
 *                  1. Strings without a # as the first character
 *                  2. Number-only hashtags
 *                  3. Hashtags with only dirty chars
 *                  4. Literally just a #
 */
export const illegalHashtagChars: RegExp = /^(?:[^#].*|#\d+|#)$/;

/**
 * @name            pasteSocialHandleInputSplitter
 * @description     Matches Any whitespace characters or commas (trims surrounding white space)
 *                  No g option due to split()in
 */
export const pasteSocialHandleInputSplitter: RegExp = /\s*(?:\s|,)\s*/;

/**
 * @name            pasteSocialContentInputSplitter
 * @description     Matches any tab, carriage return, new line, form feed or commas (not with quotes)
 *                  No g option due to split()in
 */
 export const pasteSocialContentInputSplitter: RegExp = /\t|\r|\n|\f|,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/;

/**
 * @name            matchLowercaseCharacter
 * @description     Matches at least one lowercase alpha character
 */
export const matchLowercaseCharacter: RegExp= /^(?=.*[a-z])/;

/**
 * @name            matchUppercaseCharacter
 * @description     Matches at least one uppercase alpha character
 */
export const matchUppercaseCharacter: RegExp= /^(?=.*[A-Z])/;

/**
 * @name            matchSpecialCharacter
 * @description     Matches at least one special character
 */
export const matchSpecialCharacter: RegExp= /^(?=.*[!@#\$%\^&\*\-_=\+\.\?])/;

export const minTwitterHandleLength: number = 3;
export const maxTwitterHandleLength: number = 15;

/**
 * @name            mediumStrengthPassword
 * @description     Matches at least one lowercase and one uppercase alpha character and
 *                  one special character and a minimum of 10 characters
 */
export const mediumStrengthPassword: RegExp= /^((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*\-_=\+\.\?]))(?=.{10})/;

/**
 * @name            strongStrengthPassword
 * @description     Matches at least one lowercase and one uppercase alpha character and
 *                  one special character and one numeric character and a minimum of 10 characters
 */
export const strongStrengthPassword: RegExp= /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*\-_=\+\.\?])(?=.*[0-9])))(?=.{10,})/;

/**
 * @name            noCountryCodeUSPhoneNumber
 * @description     Matches standard (no country code prefixed) US phone numbers
 */
export const noCountryCodeUSPhoneNumber: RegExp= /^((\(([0-9]{3})\))|([0-9]{3}))[- ]?([0-9]{3})[- ]?([0-9]{4})$/;

/**
 * @name            fileUriPrefixMatcher
 * @description     Matches the `file:` prefix
 */
export const fileUriPrefixMatcher: RegExp = /file:/g;

/**
 * @name            uriFileNameMatcher
 * @description     Matches acceptable filename for uri
 */
 export const uriFileNameMatcher: RegExp = /^[ A-Za-z0-9\s\.\-\_]*$/

 /**
 * @name            httpSuccessCodeMatcher
 * @description     Matches any numeric (full number) value between 200-299
 */
  export const httpSuccessCodeMatcher = RegExp( /^2\d{2}$/ );

 /**
 * @name            httpClientErrorCodeMatcher
 * @description     Matches any numeric (full number) value between 400-499
 */
  export const httpClientErrorCodeMatcher = RegExp( /^4\d{2}$/ );

 /**
 * @name            uidMatcher
 * @description     Matches any valid uid
 */
  export const uidMatcher = RegExp( /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i );

 /**
 * @name            ipMatcher
 * @description     Matches any valid ip address
 */
  export const ipMatcher = RegExp( /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ );

