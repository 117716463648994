import {getStoreAccessors} from 'vuex-typescript';
import {GlobalState} from '../state-types';

export interface SubnavLink {
    label: string,
    name: string,
    params?: {},
    query?: {},
    default?: boolean
}

export interface Subnav {
    placement?: string,
    type?: string,
    links: SubnavLink[],
}

export interface SubnavState {
    subnav: Subnav | null,
}

export const subnavModule = {
    state: {
        subnav: null,
    },

    getters: {
        getSubnav(state: SubnavState): Subnav | null {
            return state.subnav;
        },
    },

    mutations: {
        async setSubnav(state: SubnavState, subnav: Subnav | null) {
            state.subnav = subnav;
        },
    },

    actions: {}
};

const {commit, read, dispatch} = getStoreAccessors<SubnavState, GlobalState>('');

const subnavAccessor = {
    getSubnav: read(subnavModule.getters.getSubnav),
    setSubnav: commit(subnavModule.mutations.setSubnav),
}

export default subnavAccessor;
