var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pageCount > 1
    ? _c(
        "ul",
        {
          staticClass: "pb-pagination list-inline d-flex align-items-center",
          attrs: { disabled: _vm.disabled }
        },
        [
          _c("li", { staticClass: "list-inline-item" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.pageTo(1)
                  }
                }
              },
              [_vm._v("\n            1\n        ")]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-inline-item" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.pageTo(_vm.previousPageIndex)
                  }
                }
              },
              [_c("font-awesome-icon", { attrs: { icon: "chevron-left" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-inline-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.page,
                  expression: "page"
                }
              ],
              style: `max-width: ${_vm.pageCountLength}rem`,
              attrs: {
                max: _vm.pageCount,
                maxlength: _vm.pageCountLength,
                min: "1",
                placeholder: "#",
                type: "number"
              },
              domProps: { value: _vm.page },
              on: {
                blur: function($event) {
                  _vm.page = _vm.currentPage
                },
                change: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "lazy",
                      undefined,
                      $event.key,
                      undefined
                    )
                  )
                    return null
                  ;[_vm.pageTo(_vm.page), $event.target.blur()]
                },
                focus: function($event) {
                  _vm.page = ""
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.page = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-inline-item" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.pageTo(_vm.nextPageIndex)
                  }
                }
              },
              [_c("font-awesome-icon", { attrs: { icon: "chevron-right" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "list-inline-item" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.pageTo(_vm.pageCount)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.pageCount))]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }