var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      key: `pda:${_vm.params.tab}:${_vm.params.section}`,
      attrs: { "data-intent": "pda-report" },
      on: {
        "chart-action": function($event) {
          return _vm.chartActionHandler($event)
        }
      }
    },
    [
      !_vm.printMode && _vm.pageReady && _vm.ready
        ? _c("subnav", {
            key: _vm.subnavTimestamp,
            attrs: { "nav-links": _vm.subnavLinks, placement: "page" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("article", { staticClass: "container-fluid p-3 pt-0 bg-white" }, [
        _c("section", { staticClass: "bg-white" }, [
          _c(
            "div",
            { staticClass: "container-fluid p-0" },
            [
              _vm.error.code
                ? _c("div", {
                    staticClass: "p-5 text-center",
                    domProps: { innerHTML: _vm._s(_vm.error.message) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ready && _vm.reportDataReady
                ? _c("div", { staticClass: "report-container" }, [
                    _c("div", { staticClass: "report-section bg-white" }, [
                      _vm.reportReady
                        ? _c("section", [
                            !_vm.customLayout
                              ? _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    !_vm.printMode && _vm.sectionTitle
                                      ? _c("h1", { staticClass: "h3 mb-3" }, [
                                          _vm.hasPageLevelCartExport
                                            ? _c("a", {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  href: "",
                                                  title: _vm.associatedDataTableActionParameters(
                                                    _vm.pageLevelDataElementIds
                                                  ).tooltip
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm
                                                      .associatedDataTableActionParameters(
                                                        _vm.pageLevelDataElementIds
                                                      )
                                                      .html.replace("fa-lg", "")
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.pageLevelCartExport.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(_vm.sectionTitle) +
                                              "\n                                                "
                                          ),
                                          _vm.onPageHelpContent
                                            ? _c(
                                                "a",
                                                [
                                                  _c("on-page-help", {
                                                    attrs: {
                                                      "help-content":
                                                        _vm.onPageHelpContent
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _vm.filterStatusMessage
                                    ? _c("div", { staticClass: "col" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mb-0",
                                            class: _vm.filterAlertClass
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.filterStatusMessage
                                                )
                                              }
                                            }),
                                            _vm._v(
                                              "\n                                                Adjust this in "
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "alert-link",
                                                attrs: {
                                                  to: {
                                                    params: {
                                                      tab: "settings",
                                                      section: null
                                                    }
                                                  }
                                                }
                                              },
                                              [_vm._v("Settings")]
                                            ),
                                            _vm._v(
                                              ".\n                                            "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.allowExcelExport
                                    ? _c(
                                        "div",
                                        { staticClass: "col text-right" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip:hover",
                                                  value: `Download page in Excel format`,
                                                  expression:
                                                    "`Download page in Excel format`",
                                                  arg: "hover"
                                                }
                                              ],
                                              staticClass: "btn btn-success",
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.excelExport.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["fas", "file-excel"]
                                                }
                                              }),
                                              _vm._v(
                                                "\n                                                Download\n                                            "
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column-reverse" },
                              [
                                _vm.customLayout
                                  ? _c(
                                      "div",
                                      [
                                        _c(_vm.customLayout.component, {
                                          tag: "component",
                                          attrs: {
                                            "chart-data":
                                              _vm.customLayout.chartData,
                                            detail:
                                              _vm.customLayout.detail || {},
                                            params:
                                              _vm.customLayout.params || {},
                                            "report-ready": _vm.reportReady
                                          },
                                          on: {
                                            "change-setting": _vm.changeSetting,
                                            "delete-all-cart-items":
                                              _vm.deleteAllCartItems,
                                            "delete-cart-item":
                                              _vm.deleteCartItem,
                                            "handle-cart-item":
                                              _vm.handleCartItem,
                                            "prepare-for-order":
                                              _vm.prepareForOrder,
                                            "set-sort-group": function($event) {
                                              _vm.sortGroup = $event
                                            },
                                            "set-data-element-list": function(
                                              $event
                                            ) {
                                              _vm.customDataElementList = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.customLayout.afterChart
                                                ? {
                                                    key: "afterChart",
                                                    fn: function() {
                                                      return [
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.customLayout
                                                                .afterChart
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                : null
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  : [
                                      _vm.chartListData.length
                                        ? _c("chart-grid", {
                                            key: _vm.sectionKey,
                                            attrs: {
                                              "chart-wrapper-class": {
                                                "offset-lg-3":
                                                  _vm.layoutColumns === 1,
                                                "col-lg-6":
                                                  _vm.layoutColumns > 0,
                                                col: _vm.layoutColumns < 0
                                              },
                                              charts: _vm.chartListData,
                                              "charts-associated-data":
                                                _vm.chartListAssociatedData
                                            },
                                            on: {
                                              "associated-data-table-action":
                                                _vm.associatedDataTableActionHandler
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                _vm._v(" "),
                                _vm.sortGroupOptions.length
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sort-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn-group select-group d-flex",
                                              attrs: { role: "group" }
                                            },
                                            [
                                              _vm._l(
                                                _vm.sortGroupOptions,
                                                function(button) {
                                                  return [
                                                    button.hasOwnProperty(
                                                      "group"
                                                    )
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn media-body w-100",
                                                            class: [
                                                              `select-${button.color}`,
                                                              {
                                                                active:
                                                                  _vm.sortGroup ===
                                                                  button.group
                                                              }
                                                            ],
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.sortGroup =
                                                                  button.group
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            " +
                                                                _vm._s(
                                                                  button.label
                                                                ) +
                                                                "\n                                                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    button.hasOwnProperty(
                                                      "items"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "btn-group dropdown-bubble",
                                                            attrs: {
                                                              role: "group"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn dropdown-toggle media-body",
                                                                class: `select-${button.color}`,
                                                                attrs: {
                                                                  "aria-haspopup":
                                                                    "true",
                                                                  "aria-expanded":
                                                                    "false",
                                                                  "data-toggle":
                                                                    "dropdown",
                                                                  id: button.id
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                " +
                                                                    _vm._s(
                                                                      button.label
                                                                    ) +
                                                                    "\n                                                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-menu",
                                                                attrs: {
                                                                  "aria-labelledby":
                                                                    button.id
                                                                }
                                                              },
                                                              _vm._l(
                                                                button.items,
                                                                function(item) {
                                                                  return _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "dropdown-item",
                                                                      attrs: {
                                                                        href: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return item.callback.apply(
                                                                            null,
                                                                            arguments
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.label
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        : _c(
                            "section",
                            [_c("spinner", { attrs: { text: "Building..." } })],
                            1
                          )
                    ])
                  ])
                : _vm.error.code === 0
                ? _c("spinner", { staticClass: "text-secondary" })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.ready
        ? _c(
            "b-modal",
            {
              attrs: {
                "hide-footer": "",
                id: _vm.dataElementSearchModal,
                size: "lg"
              },
              on: { hidden: _vm.resetDataElementSearch },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function({ hide }) {
                      return [
                        _c("h3", [_vm._v("Data Element Search")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            attrs: { title: "Close" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return hide()
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { size: "2x", icon: ["far", "times"] }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2762839379
              )
            },
            [
              _vm._v(" "),
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.getDataElementSearchResults.apply(
                        null,
                        arguments
                      )
                    }
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      class: { "mb-3": _vm.dataElementSearchResult.length > 0 }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          autofocus: "",
                          id: "dataElementSearchQuery",
                          placeholder: "Search for a data element here",
                          required: true
                        },
                        model: {
                          value: _vm.dataElementSearchQuery,
                          callback: function($$v) {
                            _vm.dataElementSearchQuery = $$v
                          },
                          expression: "dataElementSearchQuery"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "info" } },
                            [_vm._v("Search")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dataElementSearchResult.length
                ? _c("section", [
                    _c("div", { staticClass: "alert alert-info" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(
                            Math.min(10, _vm.dataElementSearchResult.length)
                          ) +
                          " of " +
                          _vm._s(_vm.dataElementSearchResult.length) +
                          " results"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "accordion", attrs: { role: "tablist" } },
                      [
                        _vm._l(_vm.dataElementSearchResult, function(
                          result,
                          index
                        ) {
                          return [
                            index < 10
                              ? _c(
                                  "b-card",
                                  { attrs: { "no-body": "" } },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        staticClass: "p-0",
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle",
                                                value: `accordion-${index}`,
                                                expression:
                                                  "`accordion-${index}`"
                                              }
                                            ],
                                            staticClass: "text-left",
                                            attrs: {
                                              block: "",
                                              variant: "info"
                                            }
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  result.dataElement
                                                    .dataElementId
                                                )
                                              )
                                            ]),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  result.dataElement
                                                    .shortDescription
                                                ) +
                                                "\n                                    "
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "float-right badge badge-light ml-3"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(result.pages.length) +
                                                    " pages"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          accordion: "my-accordion",
                                          id: `accordion-${index}`,
                                          role: "tabpanel",
                                          visible: index === 0
                                        }
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          { staticClass: "p-0" },
                                          [
                                            result.pages.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "p-3" },
                                                  [
                                                    _c("em", [
                                                      _vm._v(
                                                        "Found on the following pages:"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "ul",
                                                      { staticClass: "m-0" },
                                                      _vm._l(
                                                        result.pages,
                                                        function(page) {
                                                          return _c(
                                                            "li",
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to:
                                                                      page.route
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        page.pageDescription
                                                                      ) +
                                                                      " > " +
                                                                      _vm._s(
                                                                        page.categoryDescription
                                                                      ) +
                                                                      "\n                                                "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("pre", {
                                              staticClass:
                                                "text-size-md bg-dark text-white m-0",
                                              staticStyle: {
                                                "white-space": "pre-wrap"
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  result.dataElement
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }