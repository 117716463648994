const BaseColors = {
    red: {
        10: '#faebeb',
        25: '#efc6ba',
        50: '#e29684',
        75: '#d86a5b',
        100: '#ce3438',
    },
    orange: {
        25: '#ffdcc1',
        50: '#ffbb86',
        75: '#f8994a',
        100: '#ed7900',
    },
    yellow: {
        25: '#ffebcd',
        50: '#ffd89d',
        75: '#fbc56c',
        100: '#f5b335',
    },
    green: {
        10: '#f0f6ed',
        25: '#d7e2ca',
        50: '#b3cb9d',
        75: '#91b876',
        100: '#6fa84f',
    },
    teal: {
        25: '#8bc7d8',
        50: '#8bc7d8',
        75: '#41acc5',
        100: '#0092b3',
    },
    blue: {
        10: '#e5eef3',
        25: '#c4d0df',
        50: '#8aa5c2',
        75: '#4f7ba4',
        100: '#005587',
    },
    purple: {
        25: '#cdcfe8',
        50: '#a4a8d5',
        75: '#8088c2',
        100: '#5f6db3',
    },
    gray: {
        25: '#cfcfcf',
        50: '#a0a0a0',
        75: '#707070',
        100: '#404040',
    },
};

export default BaseColors;
