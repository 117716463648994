
    import {Vue, Component} from 'vue-property-decorator';
    import store from "Store/store";

    @Component<LandingPage>({
        metaInfo() {
            return {
                title: '[LANDING PAGE]',
            };
        },
        components: {},
        beforeRouteEnter(to, from, next) {
            // const accountId = store.getters.getActiveAccount.id;

            try {
                next(vm => {
                    // use call back to allow navigation confirmation
                    vm.$router.push({name: 'error404'});
                });
            } catch (error) {
                console.error('Landing re-route error: ', error);
            }
        }
    })
    export default class LandingPage extends Vue {
    }
