var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pb-spinner-container", class: _vm.getClasses() },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-center pb-spinner-frame"
        },
        [
          _c(
            "div",
            { staticClass: "flex-fill text-right" },
            [_c("b-spinner", { attrs: { label: _vm.textLabel } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-fill text-left pl-2 pb-spinner-text" },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.textLabel) } })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }