
    import {Vue, Component, Watch} from 'vue-property-decorator';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from "Common/font-awesome-icon";
    // import NavigationExtra from 'Components/app/navigation/navigation-extra';
    import Subnav from 'Components/app/navigation/subnav';
    // import {Status} from 'Utilities/immutables';

    @Component<Navigation>({
        components: {
            FontAwesomeIcon,
            // NavigationExtra,
            Subnav,
        }
    })
    export default class Navigation extends Vue {
        Utils = Utils;

        // get account() {
        //     return this.$store.state?.account;
        // }
        //
        // get accountId() {
        //     return this.account?.id;
        // }
        //
        // get accounts() {
        //     let activeAccounts = this.user.accounts.filter( account => account.status === Status.ACTIVE);
        //     return Utils.sortByProperty(activeAccounts || [], 'name', 'asc');
        // }
        //
        // get user() {
        //     return this.$store.getters.getUser;
        // }
        //
        // isActiveAccount(accountId) {
        //     return this.accountId === accountId;
        // }

        // isAncestor($navType) {
        //     switch ($navType) {
        //         case 'activity':
        //             return this.$route.name === 'audienceReport' ||
        //                 this.$route.name === 'comparisonReport' ||
        //                 this.$route.name === 'personaReport' ||
        //                 this.$route.name === 'topicLibrary' ||
        //                 this.$route.name === 'uploadLibrary';
        //     }
        //
        //     return false;
        // }

        // logout() {
        //     this.$emit('logout');
        // }

        navLink(context) {
            switch (context) {
                default:
                    return {
                        name: '',
                        params: {
                        },
                    };
                // case 'audienceBuild':
                //     return {
                //         name: 'audienceBuild',
                //         params: {
                //             accountId: this.accountId,
                //             tab: AudienceTabs.PERSONA,
                //         },
                //         query: {
                //             mode: AudienceMode.NEW,
                //             subnav: false,
                //         },
                //     }
                // case 'audienceSend':
                //     return {
                //         name: 'audienceSend',
                //         params: {
                //             accountId: this.accountId,
                //         },
                //     }
                // case 'audienceSend_PLACEHOLDER':
                //     return {
                //         name: 'error404',
                //     }
                // case 'comparisonBuild':
                //     return {
                //         name: 'comparisonBuild',
                //         params: {
                //             accountId: this.accountId,
                //             tab: ComparisonTabs.PERSONAS,
                //         },
                //         query: {
                //             mode: ComparisonMode.NEW,
                //         },
                //     };
                // case 'personaBuild':
                //     return {
                //         name: 'personaBuild',
                //         params: {
                //             accountId: this.accountId,
                //             tab: PersonaTabs.DEMOGRAPHICS,
                //         },
                //         query: {
                //             mode: PersonaMode.NEW,
                //         },
                //     };
                // case 'personaCompare':
                //     return {
                //         name: 'personaCompare',
                //         params: {
                //             accountId: this.accountId,
                //         },
                //     };
            }
        }

        // async setAccount(accountId) {
        //     this.$wait.start( AccountLoaders.LOADING );
        //     const user = await this.$store.dispatch('setUserCurrentAccount', accountId);
        //     let switched: boolean;
        //     if (user) {
        //         await this.$store.dispatch('setAccount', {accountId, isInitial: true});
        //
        //         if (this.$route.name !== 'activity' || this.$route.params.accountId !== accountId) {
        //             this.$router.push({name: 'activity', params: {accountId}});
        //         } else {
        //             // Reload the Activity list
        //             // await activities.applySearchParameters()
        //         }
        //         switched = true;
        //     } else {
        //         switched = false;
        //     }
        //
        //     this.$wait.end( AccountLoaders.LOADING );
        //     return switched;
        // }

        async updateNavigation(routeName) {
            switch (routeName) {
                case 'activity':
                    // Clear any existing Activity page filters
                    window.sessionStorage.removeItem('activityPageSearchParameters');
                    break;
            }
            this.$nextTick(async () => {
                await this.$store.dispatch('setNavigation', routeName);
            });
        }
    }
