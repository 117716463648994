import * as utils from "Utilities/utils";
import * as https from "https";
import qs from "qs";
import axios, {AxiosResponse, AxiosError, CustomRequestConfig} from "axios";
import {getAuthorizationHeader} from "Server/crypto";
import {RequestError} from "Utilities/immutables";
import {isDev} from "./routes/auth";

const showDebugData: boolean = true;
const httpHost: string = "http://";
const httpsHost: string = "https://";
export const APIError: string = "API server error";
export const NoAPIResponse: string = "API is not responding";
// export const contentTypeMultiPartForm: string = 'multipart/form-data';
export const slugPattern: string = "[a-z0-9-]+";
// export const uuidPattern: string = '[a-f0-9-]{24,36}';
export const uuidPattern: string = "[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}";

const api = () => {
    let apiConfig;

    try {
        if (process.env.api) {
            apiConfig = JSON.parse(process.env.api);
        }
    } catch (error: any) {
        console.error(error.stack);
    }

    return apiConfig;
};

const getApiHost = (secure: boolean) => {
    const apiConfig = api();
    let scheme = (secure && (apiConfig.hostname !== "localhost") ? httpsHost : httpHost);
    // let scheme = (secure ? httpsHost : httpHost);

    try {
        if (apiConfig) {
            let port = normalizePort(api().port);

            port = port ? ":" + port : "";
            scheme += apiConfig.hostname + port;
        }
    } catch (error: any) {
        console.error(error.stack);
    }

    return scheme;
};

const getApiPath = (version?: string) => {
    const apiConfig = api();
    let path = apiConfig.path || '';
    if (apiConfig.hasOwnProperty('version')) {
        path = `${path}/${apiConfig.version}`
    }

    // try {
    //     if (apiConfig) {
    //         version = version === "" ? "" : version;
    //
    //         if (version === "") {
    //             path += apiConfig.path;
    //         } else {
    //             path = "/api/" + version;
    //         }
    //     }
    // } catch (error: any) {
    //     console.error(error.stack);
    // }

    return path;
};

const getApiResourceUri = (resource: string) => {
    let path = utils.trimLeadingForwardSlash(getApiPath());
    resource = utils.trimLeadingForwardSlash(resource);
    let pathElements = [getApiHost(true)];
    if (path) {
        pathElements.push(path);
    }
    pathElements.push(resource);

    // Set host to https (true) and default path/version
    return pathElements.join("/");
};

export const normalizePort = (val: string) => {
    let port: number = parseInt(val, 10);

    if (isNaN(port)) {
        // named pipe
        return val;
    }

    if (port >= 0) {
        return port;
    }

    return false;
};

const getAxiosOptions = (request) => {
    try {
        const httpsAgent = new https.Agent({rejectUnauthorized: false});
        const axiosOptions: any = {httpsAgent, headers: request.headers};
        axiosOptions.headers["Access-Control-Allow-Headers"] = "Authorization, Share-Authorization";
        axiosOptions.headers["Access-Control-Allow-Origin"] = request.headers.referrer || RequestError.INVALID_REQUEST_ORIGIN;
        console.debug("AXIOS REQUEST HEADERS: ", request.headers);

        return axiosOptions;
    } catch (error: any) {
        console.error(error.stack);
    }
};

const getAxiosInstance = axios.create();

const requestHandler = (request: CustomRequestConfig) => {
    try {
        let axiosOptions = getAxiosOptions(request);
        let authToken = request.cookies?.get("authToken"),
            authTokenShared = request.cookies?.get("authTokenShared");

        if (axiosOptions) {
            if (authToken && !request.shared) {
                delete axiosOptions.headers["Share-Authorization"];
                axiosOptions.headers["Authorization"] = getAuthorizationHeader(authToken);
            }

            if (authTokenShared && request.shared) {
                delete axiosOptions.headers["Authorization"];
                axiosOptions.headers["Share-Authorization"] = getAuthorizationHeader(authTokenShared);
            }

            request.headers = axiosOptions.headers;
            request.httpsAgent = axiosOptions.httpsAgent;
            request.paramsSerializer = (params) => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            };
        }

        if (!isDev) {
            // Start: OPS SECURITY LOGGING (Do not remove or comment out)
            console.log("\n===START PROXY OUTGOING REQUEST===");
            console.log("request: ", request.method + " - " + request.url);
            console.log("headers: ", request.headers);
            console.log("params: ", request.params);
            console.log("===END PROXY OUTGOING REQUEST===");
            // End: OPS SECURITY LOGGING  (Do not remove or comment out)
        }
    } catch (error: any) {
        console.error(error.stack);
    }

    return request;
};

const successHandler = (response: AxiosResponse) => {
    if (!isDev) {
        // Start: OPS SECURITY LOGGING (Do not remove or comment out)
        console.log("\n===START PROXY INCOMING RESPONSE===");
        console.log("request: ", response.config.method + " - " + response.config.url);
        console.log("status: ", response.status + "-" + response.statusText);
        console.log("headers: ", response.headers);
        console.log("params: ", response.config.params);
        console.log("===END PROXY INCOMING RESPONSE===");
        // End: OPS SECURITY LOGGING (Do not remove or comment out)
    // } else if (isDev && showDebugData) {
    //     console.debug("RESPONSE HANDLER RESPONSE DATA: ", response.data, JSON.stringify(response.data));
    }

    return response;
};

const errorHandler = (error: AxiosError) => {
    // Start: OPS SECURITY LOGGING (Do not remove or comment out)
    console.error("\nPROXY INCOMING RESPONSE ERROR: ", error);
    // End: OPS SECURITY LOGGING (Do not remove or comment out)

    if (error.response) {
        // The request was made and the server responded with a non 2xx status code
        if (error.response.status < 500) {
            // Return non 5xx status code response
            return Promise.resolve(error.response);
        } else {
            throw ({code: error.response.status, message: APIError});
        }
    } else if (error.request) {
        // The request was made but no response was received
        throw ({code: 500, message: NoAPIResponse});
    } else {
        // Something happened in setting up the request that triggered an Error
        throw ({code: 400, message: error.message});
    }
};

export interface MetaRequest extends Request {
    user: string; // or any other type
}

getAxiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

getAxiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

export default {getApiHost, getApiResourceUri, getAxiosInstance};
