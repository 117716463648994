var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { attrs: { id: "onPageHelp" } },
    [
      _c(
        "a",
        {
          staticStyle: { cursor: "help" },
          attrs: { href: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.showHelp.apply(null, arguments)
            }
          }
        },
        [
          _vm._t("trigger", function() {
            return [
              _c(
                "sup",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fad", "question-circle"] }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("display", function() {
        return [
          _c(
            "b-modal",
            {
              attrs: {
                centered: "",
                "content-class": "border-primary",
                "hide-footer": "",
                id: _vm.modalId,
                size: "lg"
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-header",
                  fn: function({}) {
                    return [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "modal-title text-primary mx-auto d-block font-weight-bold"
                        },
                        [_vm._v("About This Page")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "float-right text-primary",
                          attrs: { href: "", "aria-label": "Close" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$bvModal.hide(_vm.modalId)
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("h4", [_vm._v("Page Overview")]),
              _vm._v(" "),
              _vm._l(_vm.helpContent.overview, function(text, index) {
                return _c("p", {
                  key: `overview_${index}`,
                  domProps: { innerHTML: _vm._s(text) }
                })
              }),
              _vm._v(" "),
              _c("h4", [_vm._v("How to Use This Page")]),
              _vm._v(" "),
              _vm._l(_vm.helpContent.how, function(text, index) {
                return _c("p", {
                  key: `how_${index}`,
                  domProps: { innerHTML: _vm._s(text) }
                })
              }),
              _vm._v(" "),
              _c("h4", [_vm._v("What Makes It Work")]),
              _vm._v(" "),
              _vm._l(_vm.helpContent.detail, function(text, index) {
                return _c("p", {
                  key: `detail_${index}`,
                  domProps: { innerHTML: _vm._s(text) }
                })
              })
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }