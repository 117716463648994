
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import clone from 'lodash-es/clone';

    @Component
    export default class Spinner extends Vue {
        @Prop({
            type: Array,
            required: false,
            default: (_: any) => ['d-flex', 'flex-column', 'justify-content-center', 'align-items-center']
        })
        classList!: string[];

        @Prop({
            type: Boolean,
            default: true
        })
        fill!: boolean;

        @Prop({
            type: Boolean,
            default: false
        })
        noLabel!: boolean;

        @Prop({
            type: String,
            default: 'Loading...'
        })
        text!: string;

        get textLabel() {
            if ( this.noLabel ) {
                return '';
            } else {
                return this.text;
            }
        }

        getClasses() {
            let classes: string[] = clone( this.classList );

            if (this.fill) {
                classes.push('fill-space');
            }

            return classes;
        }
    }
