
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import {dereference} from 'Utilities/utils';

    @Component
    export default class FontAwesomeIcon extends Vue {
        @Prop({
            type: Boolean,
            default: false
        })
        fixedWidth;

        @Prop({
            type: String,
            required: false
        })
        flip;

        @Prop({
            type: Array|String,
            required: false
        })
        icon;

        @Prop({
            type: Boolean,
            default: false
        })
        pulse;

        @Prop({
            type: String,
            required: false
        })
        rotation;

        @Prop({
            type: String,
            required: false
        })
        size;

        @Prop({
            type: Boolean,
            default: false
        })
        spin;

        get classes() {
            // console.log('[font-awesome-icon] GETTING CLASSES...', this.icon);
            let classArray = [];
            if (typeof this.icon === 'string') {
                // Default to the solid icon family
                classArray.push(`fas`, `fa-${this.icon}`);
            } else {
                classArray = dereference(this.icon);
                classArray[1] = `fa-${classArray[1]}`; // Adjust to Font Awesome CSS naming convention
            }

            if (this.fixedWidth) {
                classArray.push('fa-fw');
            }

            if (this.flip?.length) {
                classArray.push(`fa-flip-${this.flip}`);
            }

            if (this.pulse) {
                classArray.push('fa-pulse');
            }

            if (this.rotation?.length) {
                classArray.push(`fa-rotate-${this.rotation}`);
            }

            // if (this.iconSize) {
            //     this.classes.push(`fa-${this.iconSize}`);
            // }

            if (this.size) {
                classArray.push(`fa-${this.size}`);
            }

            if (this.spin) {
                classArray.push('fa-spin');
            }

            return classArray;
        }
    }
