
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import FontAwesomeIcon from "Common/font-awesome-icon";
    // import helpAccessor from 'Store/help';

    @Component({
        components: {
            FontAwesomeIcon,
        }
    })
    export default class OnPageHelp extends Vue {
        @Prop({
            type: Object
        })
        helpContent: any;

        get modalId() {
            return `helpModal`;
        }

        // isEmptyContent(content?: string) {
        //     return (!content || content === `<p></p>` || content.trim() === ``);
        // }

        async showHelp() {
            this.$bvModal.show(this.modalId);
        }
    }
