
    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
    import FontAwesomeIcon from "Common/font-awesome-icon";
    import subnavAccessor from "Store/subnav";

    export interface NavLinkParams {
        section?: string,
        tab: string,
    }

    export interface NavLinkQuery {
        jobId?: string,
    }

    export interface NavLink {
        active?: boolean,
        default?: boolean,
        detail?: any,
        id?: string | null | undefined,
        icon?: string | string[] | null | undefined,
        label: string,
        links?: NavLink[]
        name: string | null | undefined,
        params?: NavLinkParams,
        query?: NavLinkQuery,
    }

    export interface NavElement {
        links: NavLink[],
        placement?: string,
        type?: string,
    }

    @Component({
        components: {
            FontAwesomeIcon,
        }
    })
    export default class Subnav extends Vue {
        @Prop({type: String, default: 'top'})
        placement!: string;

        @Prop({type: Object})
        navLinks!: NavElement;

        showForRoute: boolean = true;

        @Watch('$route')
        onRouteChange(routeTo, routeFrom) {
            // Hide any stuck dropdowns
            let openMenu = document.querySelector('.dropdown-menu.show');
            if (openMenu !== null) {
                openMenu.className = openMenu.className.split(' ').filter(className => className !== 'show').join(' ');
            }

            // Hide any subnav elements that no longer match
            if (!this.linksApply) {
                subnavAccessor.setSubnav(this.$store, null);
            }
        }

        get linksApply() {
            return this.navLinks?.links?.length > 0
                || this.subnav?.links?.some(link => this.$route.name === link.name
                    && this.$route.query?.tab === link.query?.tab
                    && this.$route.params?.tab === link.params?.tab
                ) || false;
        }

        get showSubnav() {
            return this.linksApply || subnavAccessor.getSubnav(this.$store) !== null;
        }

        get subnav() {
            if (this.navLinks) {
                // If the calling code supplied links, just use them now
                return this.navLinks;
            } else {
                // Check the store for subnav items
                return subnavAccessor.getSubnav(this.$store);
            }
        }

        isActive(link) {
            if (this.$route.query?.tab === undefined && this.$route.params?.tab === undefined) {
                return link.hasOwnProperty('default') ? link.default : false;
            }

            return (link.query?.tab && this.$route.query?.tab === link.query.tab) || (link.params?.tab && this.$route.params?.tab === link.params.tab);
        }

        isDisabled(link) {
            return link.disabled || false;
        }
    }
