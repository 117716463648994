import Vue from 'vue';
import VueWait from 'vue-wait';
import App from 'Components/app/app.vue';
import store from 'Store/store';
import './index.scss';

const app = new Vue( {
    render: h => h( App ),
    store,
    wait: new VueWait( {
        useVuex: true,   // You must pass this option `true` to use Vuex
        registerComponent: true,
        registerDirective: true
    } ),
    template: '<App/>',
    components: {
        App
    }
} ).$mount( '#app' );

if (window.Cypress) {
    // Only available during E2E tests
    window.app = app
}
