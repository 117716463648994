
    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
    import FontAwesomeIcon from "Common/font-awesome-icon";

    @Component({
        components: {
            FontAwesomeIcon,
        }
    })
    export default class Pagination extends Vue {
        page: number|string = 1;

        @Watch('page')
        onPageChanged(value: string, oldValue: string) {
            // Prevent input value from being longer than the max page length
            if (value.length > this.pageCountLength) {
                this.page = parseInt(oldValue);
            }
        }

        @Prop({
            type: Number,
            default: 1
        })
        currentPage!: number;

        @Watch('currentPage')
        onCurrentPageChanged(value: number) {
            this.page = value;
        }

        @Prop({
            type: Number,
            default: 10
        })
        itemsPerPage!: number;

        @Prop({
            type: Number
        })
        itemCount!: number;

        @Prop({
            type: Boolean,
            default: false
        })
        disabled!: boolean;

        get pageCount() {
            return Math.ceil(this.itemCount / this.itemsPerPage);
        }

        get pageCountLength() {
            return `${this.pageCount}`.length;
        }

        get nextPageIndex() {
            return Math.min(this.currentPage + 1, this.pageCount);
        }

        get previousPageIndex() {
            return Math.max(this.currentPage - 1, 1);
        }

        pageJumpBlur(event) {

        }

        pageJumpFocus(event) {
            event.target.value = '';
            // console.log('PAGE JUMP FOCUS', event);
        }

        pageTo(pageValue: any) {
            pageValue = Math.min(Math.max(pageValue, 1), this.pageCount); // Enforce a logical range
            this.page = parseInt(pageValue);
            this.$emit('pagination:click', pageValue);
        }
    }
