
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import Highcharts from 'highcharts';
    import highchartsMore from 'highcharts/highcharts-more';
    import {Chart} from 'highcharts-vue';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from "Components/common/font-awesome-icon";
    import ChartGrid from "Components/pda/chart-grid.vue";

    highchartsMore(Highcharts);

    @Component<PdaSummary>({
        components: {
            ChartGrid,
            highcharts: Chart,
            FontAwesomeIcon,
        }
    })
    export default class PdaSummary extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        chartData: any;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        Utils = Utils;

        get filterAlertClass(): string[] {
            let classes = ['alert', 'alert-sm'];
            classes.push(this.filterApplied ? 'alert-warning' : 'alert-info');

            return classes;
        }

        get filterApplied(): boolean {
            return this.chartData.overviewData.some((chart: any) => chart.data.filterApplied || false);
        }

        get filterStatusMessage(): string {
            return this.filterApplied ?
                `Filters are applied.` :
                `No filters applied.`;
        }
    }
