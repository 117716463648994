var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "h1",
      { staticClass: "h3 mb-3" },
      [
        _c("font-awesome-icon", {
          attrs: { icon: ["fad", "books"], "fixed-width": "" }
        }),
        _vm._v(" Data Guide")
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "row align-items-center",
        class: { "mb-3": _vm.filteredDataElements.length > 0 }
      },
      [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("b-form-input", {
                  staticClass: "form-control",
                  attrs: {
                    autofocus: "",
                    id: "dataElementSearchQuery",
                    placeholder: "Search for data elements by name or ID"
                  },
                  model: {
                    value: _vm.dataElementSearchInput,
                    callback: function($$v) {
                      _vm.dataElementSearchInput = $$v
                    },
                    expression: "dataElementSearchInput"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto text-italic" }, [
          _vm._v(
            _vm._s(_vm.filteredDataElements.length.toLocaleString()) +
              " elements found"
          )
        ]),
        _vm._v(" "),
        _c("pagination", {
          staticClass: "col-auto my-0",
          attrs: {
            "current-page": _vm.pagination.currentPage,
            "item-count": _vm.pagination.itemCount,
            "items-per-page": _vm.pagination.itemsPerPage,
            "items-per-page-options": [25, 50, 100]
          },
          on: {
            "pagination:click": _vm.paginateTo,
            "pagination:page-size": _vm.paginationSize
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table table-compact table-striped" }, [
      _c("thead", [
        _c("tr", [
          _c("th"),
          _vm._v(" "),
          _c("th", [
            _c(
              "a",
              {
                staticClass: "text-nowrap",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sortBy("dataElementId")
                  }
                }
              },
              [
                _vm._v(
                  "\n                        ID\n                        "
                ),
                _c("font-awesome-icon", {
                  class: {
                    "opacity-0":
                      _vm.sortIcon("dataElementId")[1].indexOf("sort") < 0
                  },
                  attrs: {
                    "fixed-width": "",
                    icon: _vm.sortIcon("dataElementId")
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("th", [
            _c(
              "a",
              {
                staticClass: "text-nowrap",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.sortBy("shortDescription")
                  }
                }
              },
              [
                _vm._v(
                  "\n                        Name\n                        "
                ),
                _c("font-awesome-icon", {
                  class: {
                    "opacity-0":
                      _vm.sortIcon("shortDescription")[1].indexOf("sort") < 0
                  },
                  attrs: {
                    "fixed-width": "",
                    icon: _vm.sortIcon("shortDescription")
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("th", [_vm._v("Location(s)")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.paginatedDataElements, function(dataElement) {
          return _c("tr", [
            _c("td", { staticClass: "table-cell-min" }, [
              _c("div", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.top",
                    modifiers: { hover: true, top: true }
                  }
                ],
                staticClass: "bg-transparent",
                attrs: {
                  title:
                    _vm.chartData.cartHandlers[dataElement.dataElementId]
                      ?.tooltip || ""
                },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.chartData.cartHandlers[dataElement.dataElementId]?.html
                  )
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.handleCartItem(
                      _vm.chartData.cartHandlers[dataElement.dataElementId]
                        .params
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(dataElement.dataElementId))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                    " +
                  _vm._s(dataElement.shortDescription) +
                  "\n                    "
              ),
              _c("div", { staticClass: "text-size-md" }, [
                _vm._v(_vm._s(dataElement.longDescription))
              ])
            ]),
            _vm._v(" "),
            _c("td", [
              dataElement.pages.length
                ? _c(
                    "ul",
                    { staticClass: "mb-0" },
                    _vm._l(dataElement.pages, function(page) {
                      return _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: page.route } }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(page.pageDescription) +
                                " > " +
                                _vm._s(page.categoryDescription) +
                                "\n                            "
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _vm.pagination.itemCount > _vm.pagination.itemsPerPage
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c("pagination", {
              staticClass: "my-0",
              attrs: {
                "current-page": _vm.pagination.currentPage,
                "item-count": _vm.pagination.itemCount,
                "items-per-page": _vm.pagination.itemsPerPage,
                "items-per-page-options": [25, 50, 100]
              },
              on: {
                "pagination:click": _vm.paginateTo,
                "pagination:page-size": _vm.paginationSize
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }