import Vue from 'vue';
import * as Vuex from 'vuex';
import VueWait from 'vue-wait';
// import cloneDeep from 'lodash-es/cloneDeep';
import api from 'Server/api';
import {pdaReportModule} from 'Store/pda-report';
import {subnavModule} from 'Store/subnav';
import {
    GlobalState,
    // Account,
    // User,
    // UserCredentials,
    // UserInvitation,
    // UserVerification,
    // AccountUserTokenAuth,
    // SubmitParameter,
    // SubmitOption,
    // AccountPersonaOptions,
    // AccountUser
} from './state-types';
// import {cryptoError, generateSalt, getHash} from 'Server/crypto';
// import {AxiosResponse} from 'axios';
// import {axiosResponse, isEmptyArray, isEmptyObject, isValidUserAccount} from 'Utilities/utils';
// import {Actions, invalidAuthResponse, RequestError, Status} from 'Utilities/immutables';
// import {FileMeta} from './upload/upload';
// import {Notification, Notifications, defaultNotifications} from './notification/notification';
// import {uidMatcher} from 'Utilities/regex-matchers';
// import {AccountLoaders} from './account/account';

Vue.use(Vuex);
Vue.use(VueWait);

api.getAxiosInstance.interceptors.request.use(async (request) => {
    request.headers['referrer'] = `${window.location.protocol}//${window.location.hostname}`;

    return request
}, async (error) => {
    if (error.request) {
        if (error.request.status && [401, 403].includes(error.request.status)) {
            await store.dispatch('logout');
            window.location.href = '/';
        }
    }

    return Promise.reject(error)
});

// api.getAxiosInstance.interceptors.response.use(async (response) => {
//     if (response && [401, 403].includes(response.data.code)) {
//         if (!response.config.url?.includes('2fa') && !response.config.url?.includes('login')) {
//             await store.dispatch('logout');
//             window.location.href = '/';
//         }
//     }
//
//     return response
// }, async (error) => {
//     if (error.response) {
//         if (error.response.status && [401, 403].includes(error.response.status)) {
//             await store.dispatch('logout');
//             window.location.href = '/';
//         }
//     }
//
//     return Promise.reject(error)
// });

export interface Error {
    code?: string;
    name?: string;
    type?: string;
    message: string;
}

export interface ProxyResponse {
    code: number;
    status: string;
    message?: string;
}

// export interface ListParameters {
//     sort: string;
//     order: string;
//     limit?: number;
//     offset?: number;
//     search?: string;
//     status?: any;
//     append?: boolean;
//     public?: boolean | null;
//     type?: string;
//     dataType?: string[] | string;
//     fileType?: string[] | string;
//     filters?: ListFilter[];
// }
//
// export interface ListFilter {
//     field: string;
//     value: string;
// }
//
// export interface FileTypes {
//     mime: string[];
//     ext: string[];
// }

// export const defaultFileTypes: FileTypes = {
//     mime: [
//         'csv',
//         'text/csv',
//         'text/x-csv',
//         'application/vnd.ms-excel', // Windows csv :/
//         'application/csv',
//         'application/x-csv',
//         'text/plain'
//     ],
//     ext: ['csv', 'txt']
// };

export const homepageRoute = {
    name: 'activity',
    query: {
        subnav: false
    }
};

// export const defaultUserVerification: UserVerification = {
//     questions: {one: '', two: '', three: ''},
//     answers: {one: '', two: '', three: ''}
// };
// export const defaultFileMeta: FileMeta = {id: '', name: '', type: ''};
export const defaultError: Error = {message: ''};
// export const invalidCredentials: ProxyResponse = {
//     code: invalidAuthResponse.code,
//     status: invalidAuthResponse.status += ': Invalid Credentials'
// };
// export const searchHandleLoader: string = 'searching-handles';

const store = new Vuex.Store <GlobalState>({
    state: {
        // account: {
        //     id: '',
        //     name: '',
        //     description: '',
        //     isActive: false,
        //     companyName: '',
        //     contactName: '',
        //     contactPhone: '',
        //     contactEmail: '',
        //     divId: '',
        //     offlineDatasource: '',
        //     partnerIdType: '',
        //     defaultJobPriority: 9,
        //     defaultJobParameters: '',
        //     processingState: '',
        //     maxUserSeats: 5,
        //     maxPersonaJobs: 5,
        //     maxAudienceSegments: 5,
        //     allowPersonaComparisons: false,
        //     allowDataAppends: false,
        //     allowConsumerSpend: false,
        //     allowPartnerIdUpload: false,
        //     transferStrategy: '',
        //     buildConfig: JSON.parse("{}"),
        //     segmentStrategy: JSON.parse("{}"),
        //     customSegmentStrategy: false,
        //     sftpConfig: '',
        //     submitParameters: [],
        //     audiencePriceText: '',
        //     logoFile: cloneDeep(defaultFileMeta),
        //     dictionary: {},
        //     dmaCodes: {},
        //     profiles: {},
        //     sentences: {},
        //     users: [],
        //     maxUsersReached: false,
        //     created: {},
        //     updated: {}
        // },
        // accountLoading: false,
        navigation: {
            name: '',
            timestamp: ''
        },
        page: {
            title: '',
        },
        // notifications: cloneDeep(defaultNotifications),
        // user: {
        //     id: '',
        //     firstName: '',
        //     lastName: '',
        //     email: '',
        //     phone: '',
        //     verification: {
        //         questions: {
        //             one: '',
        //             two: '',
        //             three: ''
        //         },
        //         answers: {
        //             one: '',
        //             two: '',
        //             three: ''
        //         }
        //     },
        //     timezone: '',
        //     isInternal: false,
        //     companyName: '',
        //     currentAccountId: '',
        //     inactiveTimeout: 0, // mins
        //     isActive: false,
        //     isAdmin: false,
        //     isSuperAdmin: false,
        //     status: 'inactive',
        //     lastLogin: null,
        //     lastUsed: null,
        //     accounts: [],
        //     created: {},
        //     updated: {},
        // },
        // userInteractionTimestamp: new Date(),
    },
    modules: {
        pdaReportModule,
        // accountModule,
        // activityModule,
        // audienceDistributionModule,
        // audienceModule,
        // clientModule,
        // clientPlatformModule,
        // comparisonModule,
        // helpModule,
        // insightModule,
        // jobModule,
        // notificationModule,
        // personaModule,
        // platformModule,
        subnavModule,
        // topicModule,
        // tagModule,
        // uploadModule,
    },
    getters: {
        // getAccount(state: GlobalState) {
        //     return state.account.id ?
        //         state.account :
        //         JSON.parse(sessionStorage.getItem('account') || '{}');
        // },

        // getAccountDefaultSubmitParameter(state: GlobalState) {
        //     const accountSubmitParameters: SubmitParameter[] = cloneDeep(state.account.submitParameters) || [];
        //     let defaultParameter: SubmitParameter | null;
        //
        //     // Note: Currently support 1 account submit parameter; If multiple, default select highest display order
        //     defaultParameter = accountSubmitParameters ? accountSubmitParameters.find((parameter: SubmitParameter) =>
        //         (parameter.displayOrder === 1) && parameter.options) || null : null;
        //
        //     if (defaultParameter && defaultParameter.options) {
        //         defaultParameter.submitOption = defaultParameter.options.find((option: SubmitOption) => option.displayOrder === 1) || undefined;
        //     }
        //
        //     return defaultParameter;
        // },

        // getAccountDictionary(state: GlobalState): any {
        //     return !isEmptyObject(state.account.dictionary) ?
        //         state.account.dictionary :
        //         JSON.parse(sessionStorage.getItem('dictionary') || '{}');
        // },
        //
        // getAccountLoading(state: GlobalState, rootGetters: any) {
        //     return rootGetters['wait/is'](AccountLoaders.LOADING);
        // },
        //
        // getAccountSaving(state: GlobalState, rootGetters: any) {
        //     return rootGetters['wait/is'](AccountLoaders.SAVING);
        // },
        //
        // getAccountProfiles(state: GlobalState) {
        //     return state.account.profiles;
        // },
        //
        // getDmaCodes(state: GlobalState) {
        //     return !isEmptyObject(state.account.dmaCodes) ?
        //         state.account.dmaCodes :
        //         JSON.parse(sessionStorage.getItem('dmaCodes') || '{}');
        // },
        //
        // getSentences(state: GlobalState) {
        //     return !isEmptyObject(state.account.sentences) ?
        //         state.account.sentences :
        //         JSON.parse(sessionStorage.getItem('sentences') || '{}');
        // },
        //
        // getActiveAccount(state: GlobalState) {
        //     const sessionAccount: string = sessionStorage.getItem('account') || '';
        //
        //     return state.account.id ?
        //         state.account :
        //         ((sessionAccount && sessionAccount !== 'undefined') ?
        //                 JSON.parse(sessionAccount) :
        //                 {}
        //         );
        // },

        getNavigation(state: GlobalState) {
            return state.navigation;
        },

        // getNotifications(state: GlobalState) {
        //     return state.notifications;
        // },
        //
        // getUser(state: GlobalState) {
        //     return state.user;
        // },
        //
        // getUserInteractionTimestamp(state: GlobalState) {
        //     return state.userInteractionTimestamp;
        // },
        //
        // getUserProfileStatus(state: GlobalState) {
        //     if (state.user.firstName && state.user.lastName && state.user.companyName && (state.user.verification && state.user.verification.populated)) {
        //         return Status.VALID;
        //     } else if (!state.user.verification || !state.user.verification.populated) {
        //         return `${Status.INVALID}-security`;
        //     } else {
        //         return Status.INVALID;
        //     }
        // },
        //
        // getAccountUsers(state: GlobalState) {
        //     return state.account.users;
        // },
        //
        // getSocialHandleSearching(state: GlobalState, rootGetters: any) {
        //     return rootGetters['wait/is'](`${searchHandleLoader}`);
        // }
    },
    mutations: {
        // setAccount(state: GlobalState, account: Account) {
        //     Object.assign(state.account, account);
        //     sessionStorage.setItem('account', JSON.stringify(account));
        //
        //     if (state.account.users) {
        //         for (let i = 0; i < state.account.users.length; i++) {
        //             let user: AccountUser = state.account.users[i];
        //
        //             user.isActive = Boolean(user.isActive);
        //             user.isAdmin = Boolean(user.isAdmin);
        //         }
        //     }
        // },
        //
        // setAccountUsers(state: GlobalState, users: Array<AccountUser>) {
        //     state.account.users = users;
        // },
        //
        // setAccountDictionary(state: GlobalState, dictionary: any) {
        //     state.account.dictionary = dictionary;
        // },
        //
        // setAccountLogo(state: GlobalState, file: any) {
        //     state.account.logoFile = file;
        // },
        //
        // setAccountProfiles(state: GlobalState, profiles: any) {
        //     state.account.profiles = Object.assign({}, state.account.profiles, profiles);
        // },
        //
        // setAccountSegmentStrategy(state: GlobalState, segments: any) {
        //     state.account.segmentStrategy = segments;
        // },
        //
        // setNavigation(state: GlobalState, name: string) {
        //     state.navigation.name = name;
        //     state.navigation.timestamp = Date.now().toString();
        // },
        //
        // setUser(state: GlobalState, user: any) {
        //     state.user = user;
        //     state.user.isActive = Boolean(user.isActive);
        //     state.user.isAdmin = Boolean(user.isAdmin);
        //     state.user.created = {
        //         timestamp: user.createdAt
        //     };
        //     state.user.updated = {
        //         timestamp: user.updatedAt
        //     };
        // },
        //
        // setUserInteractionTimestamp(state: GlobalState, userInteractionTimestamp: any = new Date()) {
        //     state.userInteractionTimestamp = userInteractionTimestamp;
        // },
        //
        // setUserCurrentAccount(state: GlobalState, accountId: string) {
        //     state.user.currentAccountId = accountId;
        // },
        //
        // setAccountUserAdmin(state: GlobalState, updatedUser: AccountUser) {
        //     if (state.account.users.length > 0) {
        //         const existingIndex = state.account.users.findIndex(user => user.id === updatedUser.id);
        //         if (existingIndex > -1) {
        //             state.account.users[existingIndex].isAdmin = Boolean(updatedUser.isAdmin);
        //         }
        //     }
        // },
        //
        // setAccountUser(state: GlobalState, updatedUser: AccountUser) {
        //     if (state.account.users.length > 0) {
        //         const existingIndex = state.account.users.findIndex(user => user.id === updatedUser.id);
        //         if (existingIndex > -1) {
        //             Vue.set(state.account.users, existingIndex, updatedUser);
        //         }
        //     }
        // },
        //
        // setDmaCodes(state: GlobalState, dmaCodes: any) {
        //     if (state.account.dmaCodes) {
        //         state.account.dmaCodes = dmaCodes;
        //     } else {
        //         Vue.set(state.account, 'dmaCodes', dmaCodes);
        //     }
        // },
        //
        // setNotificationsActive(state: GlobalState, active: boolean) {
        //     state.notifications.active = active;
        // },
        //
        // setNotificationsDisplayDate(state: GlobalState, timestamp: Date) {
        //     state.notifications.displayDate = timestamp;
        // },
        //
        // setNotifications(state: GlobalState, notifications: Notifications) {
        //     state.notifications = notifications;
        //     // Must use Vue.set() here to maintain reactivity on the notificiation property of the user
        //     // Vue.set(state, 'user', Object.assign( {}, state.user, { notifications } ) );
        // },
        //
        // setSentences(state: GlobalState, sentences: any) {
        //     if (state.account.sentences) {
        //         state.account.sentences = sentences;
        //     } else {
        //         Vue.set(state.account, 'sentences', sentences);
        //     }
        // },
        //
        // setAccountPersonaOptions(state: GlobalState, options: AccountPersonaOptions) {
        //     // Update submitParameters only at this time
        //     if (state.account.id = options.id) {
        //         state.account.submitParameters = options.submitParameters;
        //         sessionStorage.setItem('account', JSON.stringify(state.account));
        //     }
        // }
    },

    actions: {
        async appRefreshRequired({commit}) {
            // TODO: re-implement app meta?
            return false;

            // const currentAppVersion: string = window.localStorage.getItem('pbv') || '';
            // const currentApiVersion: string = window.localStorage.getItem('pbapiv') || '';

            try {
                // const appMeta: any = await api.getAxiosInstance.get(`/api/meta/app`);
                // const meta: any = appMeta.data;
                // const initializing: boolean = (!currentAppVersion.length || !currentApiVersion.length);
                // const upgrade: boolean = (((currentAppVersion.length >= 0) && currentAppVersion !== meta.ui?.version)
                //     || ((currentApiVersion.length >= 0) && currentApiVersion !== meta.api?.version));
                //
                // if (meta.ui && meta.api) {
                //     if (initializing || (!initializing && upgrade)) {
                //         window.localStorage.setItem('pbv', meta.ui.version);
                //         window.localStorage.setItem('pbapiv', meta.api.version);
                //         return true;
                //     } else {
                //         console.debug(`${meta.ui.name} is running: ${currentAppVersion}`);
                //         console.debug(`${meta.api.name} is running: ${currentApiVersion}`);
                //         return false;
                //     }
                // } else {
                //     throw 'Meta data error';
                // }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
                return false;
            }
        },

        async getApiHost({}) {
            const host = await api.getAxiosInstance.get(`/api/meta/host`);
            return host.data?.data || '';
        },

        // async getAccounts({}, userId: string) {
        //     const accounts = await api.getAxiosInstance.get(`/api/users/${userId}/accounts`);
        //
        //     return accounts.data?.data || [];
        // },

        // async getLastValidUserAccount({rootGetters}) {
        //     const currentUser: User = rootGetters.getUser;
        //     const lastActiveAccount: Account = rootGetters.getActiveAccount || {};
        //     let validUserAccount: any = {};
        //
        //     if (currentUser.accounts) {
        //         if (lastActiveAccount && lastActiveAccount.hasOwnProperty('id')) {
        //             if (isValidUserAccount(lastActiveAccount.id, currentUser.accounts)) {
        //                 validUserAccount = lastActiveAccount;
        //             }
        //         }
        //     }
        //
        //     return validUserAccount;
        // },

        // async setAccount({dispatch, rootGetters}, {accountId, isInitial, shared}) {
        //     const currentUser: User = rootGetters.getUser;
        //
        //     try {
        //         if (currentUser) {
        //             let lastValidUserAccount: any = await dispatch('getLastValidUserAccount');
        //
        //             if (!isInitial && lastValidUserAccount.hasOwnProperty('id') && ((lastValidUserAccount.id === accountId) || !accountId)) {
        //                 // If no account was specified, or if the specified account matches the cached version, pull account and metadata from storage
        //                 return dispatch('setAccountMetadata', lastValidUserAccount);
        //             } else {
        //                 // Pull account and metadata from API
        //                 const queryString: string = shared ? `?shared=true` : ``;
        //                 accountId = (accountId) ? accountId : (currentUser.currentAccountId || lastValidUserAccount.id);
        //                 const response: any = await api.getAxiosInstance.get(`/api/accounts/${accountId}${queryString}`);
        //                 const account: Account = response.data?.data;
        //
        //                 if (account) {
        //                     await dispatch('setAccountMetadata', account);
        //
        //                     return true;
        //                 } else {
        //                     return false;
        //                 }
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} account`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async refreshAccount({dispatch, rootGetters}, accountId: string) {
        //     const currentUser: User = rootGetters.getUser;
        //
        //     try {
        //         if (currentUser) {
        //             const response: any = await api.getAxiosInstance.get(`/api/accounts/${accountId}`);
        //             const account: Account = response.data?.data;
        //
        //             if (account) {
        //                 await dispatch('setAccountMetadata', account);
        //
        //                 return true;
        //             } else {
        //                 return false;
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} account`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountSummary({commit, rootGetters}, account: Account) {
        //     try {
        //         const body: any = {account: account, currentUserId: rootGetters.getUser.id};
        //         const response = await api.getAxiosInstance.put(`/api/accounts/${account.id}`, body);
        //
        //         if (response?.data?.data) {
        //             commit('setAccount', response.data.data);
        //             return response.data;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async setAccountMetadata(store: any, account) {
        //     const {dispatch, commit, rootGetters} = store;
        //     try {
        //         sessionStorage.setItem('account', JSON.stringify(account));
        //         tagAccessor.clearAccountTags(store);
        //         commit('setAccount', account);
        //         commit('setUserCurrentAccount', account.id);
        //         await dispatch('setAccountDictionary', account.id);
        //         await dispatch('setAccountProfiles', 'tuning');
        //         await dispatch('setDmaCodes', account.id);
        //         await dispatch('setSentences', account.id);
        //
        //         return true;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async setAccountPersonaOptions({rootGetters, commit}, accountId: string) {
        //     const currentUser: User = rootGetters.getUser;
        //
        //     try {
        //         if (currentUser) {
        //             const response: any = await api.getAxiosInstance.get(`/api/accounts/${accountId}/personaOptions`);
        //             const responseData: any = response.data?.data;
        //
        //             if (responseData) {
        //                 commit('setAccountPersonaOptions', responseData);
        //
        //                 return true;
        //             } else {
        //                 return false;
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} account`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async getPersistedAccountMetadata({rootGetters, dispatch}, store: any) {
        //     try {
        //         const accountDictionary: any = JSON.parse(sessionStorage.getItem('dictionary') || '{}');
        //         const dmaCodes: any = JSON.parse(sessionStorage.getItem('dmaCodes') || '{}');
        //         const sentences: any = JSON.parse(sessionStorage.getItem('sentences') || '{}');
        //
        //         if ((!accountDictionary || isEmptyObject(accountDictionary)) || (!dmaCodes || isEmptyObject(dmaCodes)) || (!sentences || isEmptyObject(sentences))) {
        //             if (rootGetters.getAccount && rootGetters.getAccount.id) {
        //                 await dispatch('setAccountMetadata', rootGetters.getAccount);
        //             } else {
        //                 await dispatch('logout');
        //             }
        //         }
        //
        //         return true;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async getAccountNameValidation({rootGetters}, value: string) {
        //     const currentAccount = rootGetters.getAccount;
        //     const name: string = encodeURIComponent(value);
        //
        //     try {
        //         const response = await api.getAxiosInstance.get(`/api/accounts/${currentAccount.id}/names/${name}`);
        //
        //         return response.data?.data;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async getUserEmailValidation({rootGetters}, value: string) {
        //     const currentUser: User = rootGetters.getUser;
        //     const email: string = encodeURIComponent(value);
        //
        //     try {
        //         const response = await api.getAxiosInstance.get(`/api/users/${currentUser.id}/emails/${email}`);
        //
        //         return response.data?.data;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async getAccountUsers({dispatch, rootGetters}, params: ListParameters) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //         const usersParams: Object = {
        //             sort: params.sort,
        //             order: params.order,
        //             limit: params.limit,
        //             offset: params.offset,
        //             search: params.search
        //         };
        //         const response: any = await api.getAxiosInstance.get(`/api/accounts/${currentUser.currentAccountId}/users`, {params: usersParams});
        //
        //         if (response.data?.data) {
        //             const users: AccountUser[] = response.data.data.users;
        //             await dispatch('setAccountUsers', users);
        //             return true;
        //         } else {
        //             throw 'No users';
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountUsers({commit}, users: Array<AccountUser>) {
        //     commit('setAccountUsers', users);
        // },
        //
        // async setAccountLogo({commit, getters}, file: FileMeta) {
        //     commit('setAccountLogo', file);
        //
        //     let account: Account = cloneDeep(getters.getAccount);
        //     account.logoFile = file;
        //     sessionStorage.setItem('account', JSON.stringify(account));
        //     commit('setAccount', account);
        //
        //     return account;
        // },
        //
        // async setAccountUserAdmin({commit, rootGetters}, user: AccountUser) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //         const currentAccountId = currentUser.currentAccountId;
        //         const admin: boolean = user.isAdmin || false;
        //
        //         if (currentUser) {
        //             const body: any = {
        //                 currentAccountId: currentAccountId,
        //                 currentUserId: currentUser.id,
        //                 accountUserAdmin: admin
        //             };
        //             const response: any = await api.getAxiosInstance.patch(`/api/accounts/${currentAccountId}/users/${user.id}`, body);
        //
        //             if (response.data) {
        //                 const user: any = response.data;
        //
        //                 if (user.data) {
        //                     commit('setAccountUserAdmin', user.data);
        //                 }
        //
        //                 return user;
        //             } else {
        //                 throw RequestError.INVALID_RESPONSE;
        //             }
        //         } else {
        //             throw RequestError.UNAUTHORIZED_ADMIN;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountUserStatus({commit, rootGetters}, user: AccountUser) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //         const currentAccount: any = rootGetters.getAccount;
        //         const status: string = (user.status === Status.ACTIVE || user.status === Status.INVITED) ? Status.INACTIVE : Status.ACTIVE;
        //
        //         if (currentUser) {
        //             if (!currentAccount.maxUsersReached || (currentAccount.maxUsersReached && status !== Status.ACTIVE)) {
        //                 const body: any = {
        //                     currentAccountId: currentAccount.id,
        //                     currentUserId: currentUser.id,
        //                     accountUserStatus: status
        //                 };
        //                 const response: any = await api.getAxiosInstance.patch(`/api/accounts/${currentAccount.id}/users/${user.id}`, body);
        //
        //                 if (response.data) {
        //                     const user: any = response.data;
        //
        //                     if (user.data) {
        //                         commit('setAccountUser', user.data);
        //                     }
        //
        //                     return user;
        //                 } else {
        //                     throw RequestError.INVALID_RESPONSE;
        //                 }
        //             } else {
        //                 return RequestError.MAX_USERS;
        //             }
        //         } else {
        //             throw RequestError.UNAUTHORIZED_ADMIN;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountSegmentStrategy({commit, rootGetters}, segments: any) {
        //     try {
        //         let currentAccount: any = rootGetters.getAccount;
        //
        //         if (currentAccount) {
        //             const segmentsJson: string = JSON.stringify(segments);
        //             const body: any = {currentUserId: rootGetters.getUser.id, segmentStrategy: segmentsJson};
        //             const response = await api.getAxiosInstance.patch(`/api/accounts/${currentAccount.id}`, body);
        //
        //             if (response?.data?.data) {
        //                 commit('setAccountSegmentStrategy', response.data.data.segmentStrategy);
        //                 sessionStorage.setItem('account', JSON.stringify(response.data.data));
        //
        //                 return response.data;
        //             } else {
        //                 return false;
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} account segment strategy`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountDictionary({commit}, accountId: string) {
        //     let dictionary: any = JSON.parse(sessionStorage.getItem('dictionary') || '{}');
        //     // Check storage for dictionary data
        //     if (accountId === dictionary.accountId) {
        //         // Already stored
        //         commit('setAccountDictionary', dictionary);
        //         return true;
        //     }
        //
        //     try {
        //         // TODO: At some point I believe this will be account-specific
        //         const response = await api.getAxiosInstance.get('/api/meta/dictionary');
        //         dictionary = response.data?.data;
        //
        //         if (dictionary) {
        //             sessionStorage.setItem('dictionary', JSON.stringify(Object.assign({accountId}, dictionary)));
        //             commit('setAccountDictionary', dictionary);
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setAccountProfiles({commit}, context) {
        //     try {
        //         // TODO: At some point I believe this will be account-specific
        //         const response = await api.getAxiosInstance.get(`/api/audiences/profiles?type=${context}`);
        //         const profiles = response.data;
        //
        //         if (profiles.data) {
        //             let profileData = {};
        //             profileData[context] = profiles.data;
        //
        //             commit('setAccountProfiles', profileData);
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setSentences({commit}, accountId: string) {
        //     let sentences: any = JSON.parse(sessionStorage.getItem('sentences') || '{}');
        //     // Check storage for dictionary data
        //     if (accountId === sentences.accountId) {
        //         // Already stored
        //         commit('setSentences', sentences);
        //         return true;
        //     }
        //
        //     try {
        //         // TODO: At some point I believe this will be account-specific
        //         const response = await api.getAxiosInstance.get('/api/meta/sentences');
        //         sentences = response.data?.data;
        //
        //         if (sentences) {
        //             sessionStorage.setItem('sentences', JSON.stringify(Object.assign({accountId}, sentences)));
        //             commit('setSentences', sentences);
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async sendAccountUserInvitation({rootGetters}, invite: UserInvitation) {
        //     const currentAccount = rootGetters.getAccount;
        //
        //     try {
        //         const body: UserInvitation = invite;
        //         const response = await api.getAxiosInstance.post(`/api/accounts/${currentAccount.id}/users/invite`, body);
        //
        //         return response.data?.data;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async getAccountUser({dispatch, commit, getters}, credentials: UserCredentials) {
        //     try {
        //         const response = await api.getAxiosInstance.get(`/api/accounts/${credentials.accountId}/users/${credentials.id}`);
        //         const user: any = response.data;
        //
        //         if (user && user.data) {
        //             const currentUser: User = getters.getUser;
        //
        //             if (user.data.user && (currentUser.accounts?.length || 0) > 1) {
        //                 user.data.user.accounts = currentUser.accounts;
        //             } else {
        //                 user.data.user.accounts = await dispatch('getAccounts', user.data.user.id);
        //                 window.sessionStorage.setItem('accounts', JSON.stringify(user.data.user.accounts));
        //             }
        //
        //             commit('setUser', user.data.user);
        //             commit('setUserInteractionTimestamp');
        //             return user.data.user;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setDmaCodes({commit}, accountId: string) {
        //     let dmaCodes: any = JSON.parse(sessionStorage.getItem('dmaCodes') || '{}');
        //     if (accountId === dmaCodes.accountId) {
        //         // Already stored
        //         commit('setDmaCodes', dmaCodes);
        //         return true;
        //     }
        //
        //     try {
        //         const params = {limit: 9999};
        //         const response = await api.getAxiosInstance.get(`/api/meta/dma`, {params});
        //         const dmaCodes: any = response.data?.data;
        //
        //         if (dmaCodes) {
        //             const dmaCodeObject = {accountId, dmaCodes};
        //             sessionStorage.setItem('dmaCodes', JSON.stringify(dmaCodeObject));
        //             commit('setDmaCodes', dmaCodeObject);
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async getTokenizedAccountUser({commit}, auth: AccountUserTokenAuth) {
        //     try {
        //         const response = await api.getAxiosInstance.get(`/api/auth/users/${auth.userId}/tokens/${auth.token}?accountId=${auth.accountId}&status=${auth.status}`);
        //         const user: any = response.data;
        //
        //         if (user && user.data) {
        //             commit('setUser', user.data);
        //             return user.data;
        //         } else {
        //             console.error(`🔥 Error getting tokenized account user ->`, user, response, auth);
        //             return {reason: response.data}
        //             // return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(`🔥 Error getting tokenized account user...`);
        //         console.error(error);
        //     }
        // },
        //
        // async deleteAccountFile({rootGetters}, fileId: string) {
        //     try {
        //         const currentAccount = rootGetters.getAccount;
        //         let response: AxiosResponse<any> = axiosResponse();
        //         response = await api.getAxiosInstance.delete(`/api/accounts/${currentAccount.id}/uploads/${fileId}`);
        //
        //         if (response.data) {
        //             return true;
        //         } else {
        //             throw 'File delete failed';
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },

        async isAuthenticated({rootGetters}) {
            const user: AccountUser = rootGetters.getUser;

            try {
                if (user.id) {
                    const body = {user};
                    const response = await api.getAxiosInstance.post('/api/auth/authenticated', body);
                    const authResponse = response.data;

                    if (authResponse) {
                        return authResponse;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },

        async isSharedKey({dispatch, commit}, route) {
            console.error('[ALERT] Shared key API endpoint not set up, please flesh this out!', route);
            return {token: route.params.token}; // TODO: implement API endpoint...

            try {
                const body = {
                    // accountId: route.params.accountId,
                    token: route.params.token,
                }
                const response = await api.getAxiosInstance.post(`/api/auth/shared-key`, body);
                const authResponse = response.data;

                if (authResponse.token?.length > 0 && authResponse.accountId?.length > 0) {
                    return authResponse;
                } else {
                    return false;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error('UNABLE TO RETRIEVE SHARED KEY INFO', error);
            }
        },

        // async checkCredentials({}, credentials: UserCredentials) {
        //     try {
        //         if (credentials.username) {
        //             const response: any = await api.getAxiosInstance.post('/api/auth/login', {username: credentials.username});
        //             let credentialsCheck: any = response.data?.data;
        //
        //             if (credentialsCheck) {
        //                 if (credentialsCheck.isActive && !credentialsCheck.isLocked && credentialsCheck.salt && typeof credentialsCheck.salt === 'string') {
        //                     return {salt: credentialsCheck.salt};
        //                 } else {
        //                     console.error(`${RequestError.CREDENTIAL_CHECK_FAILED}: ${credentials.username}`);
        //                     delete (credentialsCheck.salt);
        //                     return credentialsCheck;
        //                 }
        //             } else if (response) {
        //                 return response;
        //             } else {
        //                 throw `${RequestError.INVALID_RESPONSE}: ${credentialsCheck}`;
        //             }
        //         } else {
        //             throw `${RequestError.INVALID_REQUEST}`;
        //         }
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async login({dispatch, commit}, credentials: UserCredentials) {
        //     try {
        //         if (credentials.username && credentials.password && credentials.salt) {
        //             credentials.password = await getHash(credentials.password, credentials.salt || '');
        //
        //             const body: UserCredentials = credentials;
        //             const response: any = await api.getAxiosInstance.post('/api/auth/login', body);
        //             const auth: any = response.data;
        //
        //             if (auth) {
        //                 if (auth.data && auth.data.user) {
        //                     const user: AccountUser = auth.data.user;
        //                     user.accounts = await dispatch('getAccounts', user.id);
        //                     window.sessionStorage.setItem('accounts', JSON.stringify(user.accounts));
        //                     await dispatch('setUser', user);
        //                     window.localStorage.setItem('pbuser', '1');
        //                 } else if (auth.message) {
        //                     console.error(`${RequestError.LOGIN_FAILED} (${auth.message}): ${credentials.username}`);
        //                 }
        //
        //                 return auth;
        //             } else {
        //                 throw `${RequestError.INVALID_RESPONSE}: ${auth}`;
        //             }
        //         } else {
        //             throw `${RequestError.INVALID_REQUEST}`;
        //         }
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async verify2faCode({dispatch, commit}, credentials: UserCredentials) {
        //     try {
        //         if (credentials.code && typeof credentials.rememberMe === 'boolean') {
        //             const body: UserCredentials = credentials;
        //             const response: any = await api.getAxiosInstance.post('/api/auth/2fa/verification', body);
        //             const verification: any = response.data;
        //
        //             if (verification) {
        //                 return verification;
        //             } else {
        //                 throw `${RequestError.INVALID_RESPONSE}: ${verification}`;
        //             }
        //         } else {
        //             throw `${RequestError.INVALID_REQUEST}`;
        //         }
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async clearAppMetadata({commit}) {
        //     try {
        //         commit('setAccountDictionary', {});
        //         commit('setDmaCodes', {});
        //         commit('setSentences', {});
        //         window.sessionStorage.removeItem('dictionary');
        //         window.sessionStorage.removeItem('dmaCodes');
        //         window.sessionStorage.removeItem('sentences');
        //
        //         return true;
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async logout({commit, dispatch}, args?: { lastPath: string, rememberMe: boolean }) {
        //     try {
        //         let querystring: string = '';
        //
        //         if (args) {
        //             if ('lastPath' in args) {
        //                 window.sessionStorage.setItem('previousRoute', args.lastPath);
        //             }
        //
        //             if ('rememberMe' in args) {
        //                 querystring = `?rememberMe=${args.rememberMe}`;
        //             }
        //         }
        //
        //         const response = await api.getAxiosInstance.get(`/api/auth/logout${querystring}`);
        //         const auth = response.data;
        //         window.localStorage.setItem('pbexp', '');
        //         window.localStorage.setItem('pbuser', '0');
        //
        //         if (auth.data) {
        //             commit('setUser', auth.data.user);
        //
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async verifyUserSecurityQnA({dispatch, commit, rootGetters}, verification: UserVerification) {
        //     try {
        //         if (verification.questions && verification.answers) {
        //             const body: any = {id: rootGetters.getUser.id, verification: verification};
        //             const verifiedUser: User = await api.getAxiosInstance.put(`/api/auth/users/${rootGetters.getUser.id}/verification`, body);
        //
        //             if (verifiedUser && verifiedUser.hasOwnProperty('data')) {
        //                 return verifiedUser;
        //             } else {
        //                 return false;
        //             }
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async resetPassword({}, credentials: UserCredentials) {
        //     try {
        //         switch (credentials.action) {
        //             case Actions.USER_RESET:
        //             case Actions.USER_FORGOT_PW_RESET:
        //                 if (credentials.username) {
        //                     const body: any = {action: credentials.action};
        //                     const userReset: any = await api.getAxiosInstance.put(`/api/auth/users/${credentials.username}/reset`, body);
        //
        //                     if (userReset && userReset.hasOwnProperty('data')) {
        //                         return userReset.data;
        //                     } else {
        //                         return false;
        //                     }
        //                 }
        //
        //                 break;
        //             default:
        //                 return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async checkUserPassword({dispatch, rootGetters}, credentials: UserCredentials) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //
        //         if (credentials.password && (credentials.id === currentUser.id)) {
        //             const response: any = await dispatch('login', credentials);
        //
        //             return (uidMatcher.test(response?.data?.token));
        //         } else {
        //             throw ({
        //                 error: invalidCredentials
        //             });
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async clearUserState({commit}) {
        //     try {
        //         commit('setUser', defaultUserState);
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async getPersistedUser({dispatch, commit}) {
        //     let persistedUser: any = {};
        //
        //     try {
        //         const response = await api.getAxiosInstance.get('/api/auth/user?state=persisted');
        //         persistedUser = response.data || false
        //
        //         if (persistedUser.id) {
        //             const sessionAccounts: string | null = sessionStorage.getItem('accounts');
        //
        //             if (sessionAccounts) {
        //                 persistedUser.accounts = JSON.parse(sessionAccounts || '') || [];
        //             } else if (persistedUser.accounts && persistedUser.accounts.length > 0) {
        //                 window.sessionStorage.setItem('accounts', JSON.stringify(persistedUser.accounts));
        //             }
        //
        //             commit('setUser', persistedUser);
        //             await dispatch('setAccount', {isInitial: false});
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        //
        //     return persistedUser;
        // },
        //
        // async searchSocialHandles({commit}, handle: string) {
        //     try {
        //         const limit: number = 100; // Harcode for now
        //         const params: Object = {search: encodeURIComponent(handle), limit: limit};
        //         const response: any = await api.getAxiosInstance.get('/api/meta/social/handles', {params: params});
        //         const handles: any = response.data;
        //
        //         if (handles) {
        //             for (let i = 0; i < handles.data.length; i++) {
        //                 let handle = handles.data[i];
        //                 handle.selected = false;
        //             }
        //
        //             return handles.data;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },

        async setNavigation({commit}, routeName: string) {
            try {
                commit('setNavigation', routeName);
                return true;
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
                return false;
            }
        },

        // async setNotificationsActive({rootGetters, commit}) {
        //     try {
        //         let notifications: Notifications = rootGetters.getNotifications || defaultNotifications;
        //
        //         if (notifications.displayDate) {
        //             let lastDisplayDate: Date = new Date(notifications.displayDate);
        //             let latestNotification: Notification = notifications.data.reduce((a: any, b: any) => {
        //                 if (a.updated.timestamp && b.updated.timestamp) {
        //                     return new Date(a.updated.timestamp) > new Date(b.updated.timestamp) ? a : b;
        //                 }
        //             });
        //
        //             if (latestNotification && latestNotification.updated) {
        //                 const latestTimestamp: Date = latestNotification.updated.timestamp ? new Date(latestNotification.updated.timestamp) : new Date();
        //                 notifications.active = new Date(latestTimestamp.toUTCString()) > new Date(lastDisplayDate.toUTCString());
        //             }
        //         } else {
        //             notifications.active = true;
        //         }
        //
        //         commit('setNotificationsActive', notifications.active);
        //
        //         return true;
        //     } catch (error) {
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async setNotificationsDisplayDate({rootGetters, commit}, latest: Date) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //
        //         if (currentUser && latest) {
        //             commit('setNotificationsDisplayDate', latest)
        //         }
        //
        //         return true;
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async setNotificationReceipt({rootGetters, commit, dispatch}, notification: Notification) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //
        //         if (currentUser) {
        //             // const notificationData = {code: 200, data: {}}; // TODO: REMOVE AFTER TESTING!
        //             const response = await api.getAxiosInstance.post(`/api/users/${currentUser.id}/notifications/${notification.id}`);
        //             const notificationData = response.data;
        //
        //             if (notificationData?.code == 200) {
        //                 // Filter out the dismissed notification in the local data store
        //                 let notifications = rootGetters.getNotifications;
        //                 notifications.data = notifications.data.filter(item => item.id !== notification.id);
        //                 await dispatch('setNotifications', notifications);
        //
        //                 return notificationData.data;
        //             } else {
        //                 throw RequestError.UNABLE_TO_RETRIEVE;
        //             }
        //         }
        //
        //         return true;
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async setNotifications({rootGetters, dispatch, commit}, params?: ListParameters) {
        //     try {
        //         const user: User = rootGetters.getUser;
        //         const query: string = '?isActive=true';
        //         const response = await api.getAxiosInstance.get(`/api/users/${user.id}/notifications${query}`, {params: params});
        //
        //         if (response.data) {
        //             let notifications: Notifications = {
        //                 data: response.data.data,
        //                 active: false,
        //                 displayDate: rootGetters.getNotifications.displayDate || null
        //             };
        //
        //             commit('setNotifications', notifications);
        //
        //             if (!isEmptyArray(notifications.data)) {
        //                 await dispatch('setNotificationsActive');
        //             } else {
        //                 commit('setNotificationsActive', false);
        //             }
        //
        //             return true;
        //         } else {
        //             throw RequestError.UNABLE_TO_RETRIEVE;
        //         }
        //     } catch (error) {
        //         console.error(error.stack);
        //         return false;
        //     }
        // },
        //
        // async setUser({commit}, user: AccountUser) {
        //     try {
        //         commit('setUser', user);
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setUserProfile({commit, rootGetters}, {profile, token, rememberMe}) {
        //     try {
        //         let body: any = {
        //             currentAccountId: profile.currentAccountId,
        //             userProfile: profile
        //         };
        //         let route: string = '';
        //
        //         switch (profile.status) {
        //             case Status.INVITED:
        //                 body.registrationToken = token;
        //                 body.rememberMe = rememberMe;
        //                 route = `auth/users/${profile.id}/register`;
        //                 break;
        //             default:
        //                 route = `users/${profile.id}/profile`;
        //                 break;
        //         }
        //
        //         const response = await api.getAxiosInstance.put(`/api/${route}`, body);
        //
        //         let userData = response?.data?.data;
        //         if (userData) {
        //             const currentUser: User = rootGetters.getUser;
        //             const sessionAccounts: string | null = sessionStorage.getItem('accounts');
        //             userData.accounts = currentUser.accounts ? currentUser.accounts : sessionAccounts;
        //             console.log('SETTING USER:', userData)
        //             commit('setUser', userData);
        //
        //             return response.data;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //         return false;
        //     }
        // },
        //
        // async setUserEmail({commit, rootGetters}, credentials: UserCredentials) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //         const currentAccountId: string = currentUser.currentAccountId;
        //
        //         if (currentUser) {
        //             const body: any = {currentAccountId, username: credentials.username};
        //             const response: any = await api.getAxiosInstance.patch(`/api/users/${currentUser.id}`, body);
        //             const user: any = response.data;
        //
        //             if (user && user.hasOwnProperty('data')) {
        //                 const sessionAccounts: string | null = sessionStorage.getItem('accounts');
        //                 user.data.accounts = currentUser.accounts ? currentUser.accounts : sessionAccounts;
        //                 commit('setUser', user.data);
        //                 return response.data;
        //             } else {
        //                 return false;
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} user email`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setUserPassword({dispatch, rootGetters}, credentials: UserCredentials) {
        //     try {
        //         const currentUser: User = rootGetters.getUser;
        //
        //         if (credentials.password && (credentials.id === currentUser.id)) {
        //             if (credentials.action === 'update') {
        //                 const response: any = await dispatch('login', {
        //                     username: credentials.username,
        //                     password: credentials.password
        //                 });
        //
        //                 if (response && response.hasOwnProperty('data')) {
        //                     return {code: 400, error: 'Password cannot match current password'};
        //                 }
        //             }
        //
        //             credentials.salt = await generateSalt();
        //             credentials.password = await getHash(credentials.password, credentials.salt || '');
        //
        //             if (credentials.salt && credentials.password) {
        //                 const body: any = {
        //                     currentAccountId: currentUser.currentAccountId,
        //                     currentUserId: currentUser.id,
        //                     salt: credentials.salt,
        //                     password: credentials.password,
        //                     token: credentials.token,
        //                     action: credentials.action
        //                 };
        //                 let route: any = '';
        //
        //                 switch (credentials.action) {
        //                     case Actions.REGISTER:
        //                         route = `auth/users/${credentials.id}/${credentials.action}`;
        //                         break;
        //                     case Actions.USER_RESET:
        //                         route = `auth/users/${credentials.id}/reset`;
        //                         break;
        //                     default:
        //                         route = `users/${credentials.id}`;
        //                         break;
        //                 }
        //
        //                 const response = await api.getAxiosInstance.patch(`/api/${route}`, body);
        //
        //                 if (response && response.hasOwnProperty('data')) {
        //                     return response.data;
        //                 } else {
        //                     return false;
        //                 }
        //             } else {
        //                 throw cryptoError;
        //             }
        //         } else {
        //             ;
        //             throw({error: invalidCredentials});
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setUserCurrentAccount({commit, rootGetters}, accountId: string) {
        //     try {
        //         let currentUser: any = rootGetters.getUser;
        //
        //         if (currentUser) {
        //             const body: any = {currentAccountId: accountId};
        //             const response: any = await api.getAxiosInstance.patch(`/api/users/${currentUser.id}`, body);
        //             const user: any = response.data;
        //
        //             if (user && user.hasOwnProperty('data')) {
        //                 const sessionAccounts: string | null = sessionStorage.getItem('accounts');
        //                 user.data.accounts = currentUser.accounts ? currentUser.accounts : sessionAccounts;
        //                 commit('setUser', user.data);
        //                 return true
        //             } else {
        //                 return false;
        //             }
        //         } else {
        //             throw `${RequestError.UNABLE_TO_UPDATE} current account`;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async setUserInteractionTimestamp({commit, rootGetters}, userInteractionTimestamp?: any) {
        //     commit('setUserInteractionTimestamp', userInteractionTimestamp);
        // },
        //
        // async setUserVerification({rootGetters, commit}, verification: any) {
        //     try {
        //         let currentUser: any = rootGetters.getUser;
        //         let currentAccountId: string = rootGetters.getUser.currentAccountId;
        //
        //         const body: any = {currentAccountId, verification, currentUserId: currentUser.id};
        //         const response: any = await api.getAxiosInstance.patch(`/api/users/${currentUser.id}`, body);
        //         const user: any = response.data;
        //
        //         if (user && user.hasOwnProperty('data')) {
        //             commit('setUser', user.data);
        //             return user.data;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // },
        //
        // async userEmail2faVerification({rootGetters, commit}, credentials: UserCredentials) {
        //     try {
        //         let currentUser: any = rootGetters.getUser;
        //
        //         const body: any = {userEmail: currentUser.email, newUserEmail: credentials.username};
        //         const response: any = await api.getAxiosInstance.post(`/api/users/${currentUser.id}/email/2fa`, body);
        //         const user: any = response.data;
        //
        //         if (user && user.hasOwnProperty('data')) {
        //             return user.data;
        //         } else {
        //             return false;
        //         }
        //     } catch (error) {
        //         // TODO: Need to add logger
        //         console.error(error);
        //     }
        // }
    }
});

// export const defaultUserState = cloneDeep(store.state.user);
export default store;
