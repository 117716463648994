var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "main",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isAuthenticated || !_vm.$route.meta.requiresAuth,
          expression: "isAuthenticated || !$route.meta.requiresAuth"
        }
      ],
      staticClass: "app h-100"
    },
    [
      _c("section", [
        _c("header", { staticClass: "topbar bg-primary text-white" }, [
          _c(
            "section",
            { staticClass: "container-fluid position-relative h-100" },
            [
              _c("img", {
                staticClass: "h-100 img-fluid snap-left position-absolute",
                attrs: { src: _vm.logo, alt: "" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row align-items-end h-100 pb-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h2", {
                    staticClass: "text-white mb-0",
                    domProps: { innerHTML: _vm._s(_vm.headerTitle) }
                  })
                ]),
                _vm._v(" "),
                _vm.headerExtra.length
                  ? _c("div", {
                      staticClass: "col-auto px-0",
                      domProps: { innerHTML: _vm._s(_vm.headerExtra) }
                    })
                  : _vm._e()
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "container-fluid p-0 mb-3", attrs: { id: "appView" } },
          [
            _c("router-view", {
              key: _vm.routeKey,
              attrs: { event: _vm.appEvent },
              on: {
                "set-header-title": _vm.setHeaderTitle,
                "set-header-extra": _vm.setHeaderExtra,
                "set-logo-filename": _vm.setLogoFilename
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center text-white" }, [
          _vm._v("© " + _vm._s(_vm.year) + " Wiland, Inc. All rights reserved.")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }