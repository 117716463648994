
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import {Chart} from 'highcharts-vue';
    import * as ReportUtilities from "Utilities/reports";
    import * as Utils from 'Utilities/utils';
    import ChartAssociatedData from "Components/pda/chart-associated-data";
    import FontAwesomeIcon from "Common/font-awesome-icon";

    @Component({
        components: {
            highcharts: Chart,
            ChartAssociatedData,
            FontAwesomeIcon
        }
    })
    export default class ChartGrid extends Vue {
        @Prop({
            type: Array,
            default: () => [],
        })
        chartsAssociatedData?;

        @Prop({
            type: Array,
            // default: () => {},
        })
        charts;

        @Prop({
            type: String | Object,
            default: 'col-6'
        })
        chartWrapperClass?;

        Utils = Utils;

        get includesChartAssociatedData(): boolean {
            if (!this.charts.length || !this.chartsAssociatedData.length) {
                return false;
            }

            return this.charts.some((chart: any) => {
                return this.chartAssociatedData(chart.chartId);
            });
        }

        chartAssociatedData(chartId: string) {
            if (!this.chartsAssociatedData.length) {
                return false;
            }

            return this.chartsAssociatedData.find(item => item.chartId === chartId)?.data || false;
        }

        chartHash(chart: any): string {
            return chart.data.elementId || chart.data.id;
        }

        isHighcharts(chart: any) {
            // if (chart.chartId?.indexOf(ReportUtilities.NON_CHART_PREFIX) > -1) {
            //     return false;
            // }

            return true;
        }

        associatedDataTableAction(chartId: string, params: any) {
            this.$emit('associated-data-table-action', {chartId, params});
        }

        // updateChartAssociatedData(chartId: string, params: any) {
        //     // console.log(`UPDATE COMPONENT EVENT @ ${chartId}`, params);
        //     this.$emit('update-chart-associated-data', {chartId, params});
        // }
    }
