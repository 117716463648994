// import Vue from 'vue';
import {getStoreAccessors} from 'vuex-typescript';
import api from 'Server/api';
import {GlobalState} from 'Store/state-types';

export interface PdaReportState {
    report: PdaReport,
}

export interface PdaReport {
    // id: string,
    // name: string,
    // type: string,
    // mode: string,
    // tags: string[],
    // fetchedAt: string
}

export const pdaReportModule = {
    state: {
    },

    getters: {

    },

    mutations: {

    },

    actions: {
        async getPdaReportData({rootGetters}, shareToken: string) {
            try {
                const response = await api.getAxiosInstance.get(`/api/pda-report/${shareToken}`);
                return response?.data;
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
            }
        },
    },
};

const {read, dispatch} = getStoreAccessors<PdaReportState, GlobalState>('');
const pdaReportAccessor = {
    // getPdaReport: read(pdaReportModule.getters.getPdaReport),
};

export default pdaReportAccessor;
