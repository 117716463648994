var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "associated-chart-data text-center text-primary" },
    [
      _vm.associatedData("header")
        ? _c("div", [_vm._v(_vm._s(_vm.associatedData("header")))])
        : _vm._e(),
      _vm._v(" "),
      ["table", "mixed"].includes(_vm.associatedData("format"))
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.associatedData("detail"), function(
              detailSegment,
              segmentIndex
            ) {
              return _c(
                "div",
                { staticClass: "col", class: _vm.wrapperClass(detailSegment) },
                [
                  detailSegment.hasOwnProperty("columns")
                    ? [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-striped table-sm text-size-md w-100 text-left",
                            attrs: {
                              "data-allow-export": _vm.allowTableExport(
                                detailSegment
                              ),
                              "data-intent": _vm.dataTableIndicator,
                              id:
                                detailSegment.id ||
                                `detail-table_${Math.random()}`
                            }
                          },
                          [
                            _c("thead", [
                              detailSegment.hasOwnProperty("title")
                                ? _c(
                                    "tr",
                                    {
                                      attrs: {
                                        "data-intent": _vm.tableTitleIndicator
                                      }
                                    },
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-white border-0 p-0",
                                          attrs: {
                                            colspan:
                                              detailSegment.columns.length + 1
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center"
                                            },
                                            [
                                              detailSegment.hasOwnProperty(
                                                "tableAction"
                                              )
                                                ? _c("div", {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "bg-transparent px-1",
                                                    attrs: {
                                                      title:
                                                        detailSegment
                                                          .tableAction
                                                          .tooltip || ""
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        detailSegment
                                                          .tableAction.html
                                                      )
                                                    },
                                                    on: _vm._d({}, [
                                                      detailSegment.tableAction
                                                        .event,
                                                      function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.tableAction(
                                                          detailSegment.tableAction,
                                                          detailSegment
                                                        )
                                                      }
                                                    ])
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "bg-primary p-1 flex-grow-1 text-center",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    detailSegment.title
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              detailSegment.hasOwnProperty("subtitle")
                                ? _c(
                                    "tr",
                                    {
                                      attrs: {
                                        "data-intent":
                                          _vm.tableSubtitleIndicator
                                      }
                                    },
                                    [
                                      _c("th", {
                                        staticClass:
                                          "text-primary border-0 text-center",
                                        attrs: {
                                          colspan:
                                            detailSegment.columns.length + 1
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            detailSegment.subtitle
                                          )
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "tr",
                                _vm._l(detailSegment.columns, function(
                                  columnTitle,
                                  columnIndex
                                ) {
                                  return _c("th", [
                                    _vm.allowTableSort(segmentIndex)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-nowrap",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.sortColumn(
                                                  segmentIndex,
                                                  columnIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(columnTitle)
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              class: {
                                                "opacity-0":
                                                  _vm
                                                    .sortIcon(
                                                      segmentIndex,
                                                      columnIndex
                                                    )[1]
                                                    .indexOf("sort") < 0
                                              },
                                              attrs: {
                                                "fixed-width": "",
                                                icon: _vm.sortIcon(
                                                  segmentIndex,
                                                  columnIndex
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(columnTitle)
                                          }
                                        })
                                  ])
                                }),
                                0
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.sortedTableData(segmentIndex),
                                function(row) {
                                  return _c(
                                    "tr",
                                    _vm._l(row, function(cell) {
                                      return _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.formattedCellValue(cell)
                                          )
                                        }
                                      })
                                    }),
                                    0
                                  )
                                }
                              ),
                              0
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  detailSegment.hasOwnProperty("html")
                    ? [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(detailSegment.html) }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            }),
            0
          )
        : _vm.associatedData("format") === "html"
        ? _c("p", {
            domProps: { innerHTML: _vm._s(_vm.associatedData("detail")) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }