
    export const dataTableIndicator = 'chart-associated-data';
    export const tableTitleIndicator = 'table-title';
    export const tableSubtitleIndicator = 'table-subtitle';

    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
    import * as Utils from 'Utilities/utils';
    import FontAwesomeIcon from "Common/font-awesome-icon.vue";

    interface AssociatedData {
        title?: string,
        detail: string,
        format?: string,
    }

    @Component({
        components: {FontAwesomeIcon}
    })
    export default class ChartAssociatedData extends Vue {
        @Prop({
            type: Object,
            default: () => {
            }
        })
        data?: AssociatedData;

        dataTableIndicator = dataTableIndicator;
        tableTitleIndicator = tableTitleIndicator;
        tableSubtitleIndicator = tableSubtitleIndicator;
        Utils = Utils;
        tableSorts = [];

        mounted() {
            if (this.associatedData('format') === 'table' && this.associatedData('detail').some((detail, index) => this.allowTableSort(index))) {
                this.tableSorts = this.associatedData('detail')
                    .map((detail, index) => {
                        // Find column index
                        return {
                            column: detail.columns.findIndex(column => column === detail.sort?.column) || 0,
                            direction: detail.sort?.direction || 'desc',
                        };
                    });
            }
        }

        get associatedDataComponents(): AssociatedData {
            return Object.assign({}, this.defaultData, this.data);
        }

        get defaultData(): AssociatedData {
            return {
                detail: 'UNKNOWN',
                format: 'table',
            }
        };

        get detailClass() {
            if (this.associatedDataComponents['detail_class'] || null) {
                // Allow override in case these are not persona vs baseline values
                return this.associatedDataComponents['detail_class'];
            }

            return this.associatedDataComponents['persona_value'] <= this.associatedDataComponents['baseline_value'] ?
                'value-less' :
                'value-greater';
        }

        allowTableExport(table: any) {
            return table.hasOwnProperty('export') ? table.export : true;
        }

        allowTableSort(segmentIndex: number) {
            return this.associatedData('detail')[segmentIndex].hasOwnProperty('sort');
        }

        associatedData(item) {
            return this.associatedDataComponents[item];
        }

        formattedCellValue(value) {
            const isNumericValue = !isNaN(+value);
            // console.log(`${isNumericValue ? 'Numeric' : 'Non-numeric' } value = "${value}" (FORCED NUMERIC: ${Number(value)})`);
            return value;
        }

        sortColumn(segmentIndex: number, columnIndex: number) {
            if (this.tableSorts[segmentIndex].column === columnIndex) {
                this.tableSorts[segmentIndex].direction = this.tableSorts[segmentIndex].direction === 'desc' ? 'asc' : 'desc';
            } else {
                // Default to a descending sort EXCEPT for the first column, which should always be a label
                this.tableSorts[segmentIndex].direction = columnIndex === 0 ? 'asc' : 'desc';
            }
            this.tableSorts[segmentIndex].column = columnIndex;
        }

        sortedTableData(segmentIndex: number) {
            const sourceTableData = this.associatedData('detail')[segmentIndex].data;
            if (!this.allowTableSort(segmentIndex) || this.tableSorts[segmentIndex] === undefined) {
                return sourceTableData;
            }

            return Utils.sortArray(
                sourceTableData,
                this.tableSorts[segmentIndex].column,
                this.tableSorts[segmentIndex].direction
            );
        }

        sortIcon(segmentIndex: number, columnIndex: number) {
            let sortIcon = 'circle';
            if (this.tableSorts[segmentIndex]?.column === columnIndex) {
                sortIcon = this.tableSorts[segmentIndex].direction === 'desc' ? 'sort-amount-down' : 'sort-amount-down-alt';
            }

            return ['fas', sortIcon];
        }

        tableAction(event: any, detailSegment: any) {
            event.params.table = structuredClone(detailSegment);
            delete event.params.table.id;
            delete event.params.table.sort;
            delete event.params.table.tableAction;

            this.$emit('associated-data-table-action', event.params);
        }

        // updateComponent(segmentIndex, params: any) {
        //     this.$emit('update-chart-associated-data', params);
        //     // console.log(`UPDATE COMPONENT EVENT @ ${segmentIndex}`, params);
        // }

        wrapperClass(segment) {
            let wrapperClass: any = {};

            if (segment.hasOwnProperty('wrapperClass')) {
                segment.wrapperClass.split(' ').forEach(className => wrapperClass[className] = true);
            }
            if (segment.hidden) {
                wrapperClass['d-none'] = true;
            }

            return wrapperClass;
        }
    }
